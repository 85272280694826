import './App.css';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
// import wordlist from './wordle.json';
import { useStateWithLocalStorage } from './Storage.js';
import { storeDailyGameSpecificState, agGameStateNewGameOrLoadDaily, agSwapGames } from './GameStorage.js';
import { checkWinOneBoard,  
  computeSatisfactionStandalone, checkWin, checkNewGame,
  agUpdateFnFromGameStateFn, gsUpdateFnFromGameStateValues, getGamekeyStandalone, agNewPracticeGame, getGameStateNewGame, gsGiveUp, getShareText, gsTypeLetter, gsTypeBackspace, 
  gsTryEnter, gsToggleBoardEmphasis, gsTogglePostgameRows, gsNoLongerAnimatingClue, countNumTurnsByGameHistory, getBoardToNetworkBA as getBoardToNetworkBA, getKeyboardAimsOneBoardFromNetwork, getIsWinByBoardBA} from './Mechanics.js';
import { getFreshToAnimateIfNull, gsHandleClueAnimationEnd, getIsStillAnimatingClue, gsHandleGivenUpAnimationEnd } from './Animate.js';
import { getMoralDataIfIsMoral, } from './Moral.js';
import { RulesModal } from './Rules.js';
import { SettingsModal } from './Settings.js';
import { GameAreaNEW } from './Gameboard.js';
import { computeAllKeyboardAims, KeyboardArea } from './Keyboard.js';
import { MAX_LIES, MAJOR, MINOR, 
  INFO_ICON, SETTINGS_ICON, 
  UNSHARED, SHARE_SUCCESS, SHARE_ERROR, DEFAULT_WORD_LENGTH, DEFAULT_NUM_BOARDS, TURN_LIMIT } from './Constants.js';

// function getOrdinal(n) {
//   const onesDigit = (Math.abs(n) % 10);
//   return (n + CARDINAL_TO_ORDINAL_SUFFIX[onesDigit]);
// }

function GameHeader(props) {
  function setIsShowSettingsTrue() {
    props.setIsShowSettings(true);
  }

  function setIsShowRulesTrue() {
    props.setIsShowRules(true);
  }

  return (
    <header className="game-header">
      <button className="game-header-button left" value="show-rules" onClick={setIsShowRulesTrue}>{INFO_ICON}</button>
      <div className="game-header-title left"><div>either</div></div><div className="game-header-title right">ordle</div>
      <button className="game-header-button right" value="show-settings" onClick={setIsShowSettingsTrue}>{SETTINGS_ICON}</button>
    </header>
  )
}

function DailySelector({isDaily, dailyNum, toggleIsDaily}) {
  return (
    <div className="daily-selector">
      {isDaily ? 
        <div className="daily-option left" /> : 
        <button className="big-button daily-option left" onClick={() => toggleIsDaily(true)}>Daily</button>}
      <div className="daily-selector-title">{isDaily ? "DAILY #" + dailyNum : "PRACTICE"}</div>
      {isDaily ? 
        <button className="big-button daily-option right" onClick={() => toggleIsDaily(false)}>Practice</button> : 
        <div className="daily-option right" />}
      {/* <div className="daily-option left">Free play</div>
      <Switch className="switch" onChange={props.onChange} checked={props.isDaily} onColor="#0000FF" width={56} height={28} />
      <div className="daily-option right">Daily puzzle</div> */}
    </div>
  );
}


// BEGIN NEW

// const getTurnToComputed = makeGetTurnToComputed();

function App(props) {
  // METHODS

  // state functions

  function setActiveGameStateFn(updateFunction) {
    setAllGames(agUpdateFnFromGameStateFn(updateFunction));
  }

  function setActiveGameStateValues(newValues) {
    setActiveGameStateFn(gsUpdateFnFromGameStateValues(newValues));
  }
  
  function setIsOverridingTurnLimit(checked) {
    setActiveGameStateValues({isOverridingTurnLimit: checked});
  }

  // action functions that modify game state

  function toggleIsDaily(checked) {
    doSwapGames({isDaily: checked, dailyNum: null});
  }
  
  function doSwapGames(stateToChange) {
    setAllGames(oldAllGames => agSwapGames(oldAllGames, stateToChange));
  }

  function doNewPracticeGame() { // should only be called when the key already exists in allGames
    console.log("doNewPracticeGame");
    setAllGames(agNewPracticeGame);
    setIsShowSettingsWithResets(false);
  }

  function doGiveUp() {
    setActiveGameStateFn(gsGiveUp);
    setIsShowSettingsWithResets(false);
    // scrollToBottom();
    // setNeedsToScrollTo("bottom");
  }
  
  function doShare() {
    const text = getShareText(activeGameState, isWinAfter, isDarkMode, isHighContrast);
    // if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text).then(
      // () => {alert("Copied to clipboard");},
      () => {setActiveGameStateValues({shareCopyStatus: SHARE_SUCCESS,});},
      (err) => {
        console.log("Could not copy to clipoboard: " + err);
        setActiveGameStateValues({shareCopyStatus: SHARE_ERROR,});
      }
    );
  }

  function doTypeLetter(letter) {
    setActiveGameStateFn(oldGameState => gsTypeLetter(oldGameState, letter));
    scrollToBottom();
    // setNeedsToScrollTo("bottom");
  }

  function doTryEnter() {
    setActiveGameStateFn(gsTryEnter);
    // scrollToBottom();
    // setNeedsToScrollTo("bottom");
  }
  
  function doTypeBackspace() {
    setActiveGameStateFn(gsTypeBackspace);
    scrollToBottom();
    // setNeedsToScrollTo("bottom");
  }
  
  function doTypeEscape() {
    setIsShowRulesWithStart(false);
    setIsShowSettingsWithResets(false);
  }

  function doToggleBoardEmphasis(boardNumClicked) {
    setActiveGameStateFn(oldGameState => gsToggleBoardEmphasis(oldGameState, boardNumClicked));
  }

  function doHandleClueAnimationEnd(event, eventViewingId, eventRowNum) {
    if (event.animationName === "vertical-flip") {
      setActiveGameStateFn(oldGameState => gsHandleClueAnimationEnd(oldGameState, eventViewingId, eventRowNum));
    }
  }

  function doHandleGivenUpAnimationEnd(event, eventViewingId) {
    if (event.animationName === "given-up-show") {
      setActiveGameStateFn(oldGameState => gsHandleGivenUpAnimationEnd(oldGameState, eventViewingId));
    }
  }

  function handleKeydown(event) {
    const lowerKey = event.key.toLowerCase();
    if (lowerKey.length === 1 && 'a' <= lowerKey && lowerKey <= 'z') {
      doTypeLetter(lowerKey);
    } else if (lowerKey === 'backspace') {
      event.preventDefault();
      doTypeBackspace();
    } else if (lowerKey === 'escape') {
      doTypeEscape();
    } else if (lowerKey === 'enter' || lowerKey === 'return') {
      doTryEnter();
    }
  }

  function doTogglePostgameRows(rowNum) {
    setActiveGameStateFn((gameState) => gsTogglePostgameRows(gameState, rowNum))
  }

  function maxLiesAdd(summand) {}

  // functions that affect DOM / localStorage used in effects
  
  function scrollToBottom() {
    // bottomElementRef.current.scrollIntoView({behavior: "auto"});
    const keyboardHeight = keyboardAreaRef.current.clientHeight;
    // console.log("keyboard area height", keyboardHeight);
    bottomElementRef.current.style.transform = "translateY(" + keyboardHeight + "px)";
    bottomElementRef.current.scrollIntoView({behavior: "smooth", block: "nearest"});
  }

  // function handleAnimationEnd(event) {
  //   if (event.animationName === "vertical-flip") {
  //     console.log("handleAnimationEnd", activeGameState, gsNoLongerAnimatingClue(activeGameState), '>', event.pseudoElement, '<');
  //     setActiveGameStateFn(gsNoLongerAnimatingClue);
  //     scrollToBottom();
  //   }
  // }

  function handleNextRowAnimationStart(event) {
    if (event.animationName === "next-row") {
      scrollToBottom();
    }
  }

  function handleResize(event) {
    
  }

  // STATE 

  // settings
  // const [isAbsurd, setIsAbsurd] = useState(false);
  const maxLies = MAX_LIES;
  // const whichHardMode = NOT_HARD_MODE;
  const [isDarkMode, setIsDarkModeLS] = useStateWithLocalStorage('useDarkMode', window.matchMedia('(prefers-color-scheme: dark)').matches);
  const [isHighContrast, setIsHighContrastLS] = useStateWithLocalStorage('useHighContrast', false);
  const [isShapeKeyboard, setIsShapeKeyboardLS] = useStateWithLocalStorage('useShapeKeyboard', false);
  // const [isMiniKeyboard, setIsMiniKeyboardLS] = useStateWithLocalStorage('useMiniKeyboard', false);
  const isMiniKeyboard = false;
  const setIsMiniKeyboardLS = null;
  // const isDailyInitial = true;
  const gameParamsInitial = {
    wordLength: DEFAULT_WORD_LENGTH,
    numBoards: DEFAULT_NUM_BOARDS,
    isDaily: true,
    dailyNum: null,
    isAbsurd: false,
  }

  // modal
  const [isShowRulesOnStart, setIsShowRulesOnStartLS] = useStateWithLocalStorage('showRulesOnStart', true);
  const [isShowRules, setIsShowRulesStateOnly] = useState(isShowRulesOnStart);
  const setIsShowRulesWithStart = (newShowRules) => {
    setActiveGameStateValues({
      maxLiesAddMessage: '',
      shareCopyStatus: UNSHARED,
    });
    setIsShowRulesStateOnly(newShowRules);
    if (!newShowRules) {
      setIsShowRulesOnStartLS(false);
    }
  };
  const [isShowSettings, setIsShowSettingsNoResets] = useState(false);
  const setIsShowSettingsWithResets = (newShowRules) => {
    setActiveGameStateValues({
      maxLiesAddMessage: '',
      shareCopyStatus: UNSHARED,
    });
    setIsShowSettingsNoResets(newShowRules);
  };

  // game-specific state
  const [allGames, setAllGames] = useState(agGameStateNewGameOrLoadDaily({nextViewingId: 0,}, gameParamsInitial));
  const activeGameState = allGames.gameStates[allGames.activeGamekey];
  // console.log("gameState right away", gameState);
  // const [getTurnToComputed, ] = useState(makeGetTurnToComputed);

  // // queueing additional effects and DOM

  const keyboardAreaRef = useRef(null);

  // const [needsToScrollTo, setNeedsToScrollTo] = useState(null);

  // additional effects and DOM
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  
  // useEffect(() => {
  //   window.addEventListener('animationend', handleAnimationEnd);

  //   return () => {
  //     window.removeEventListener('animationend', handleAnimationEnd);
  //   };
  // }, []);
  
  
  useEffect(() => {
    window.addEventListener('animationstart', handleNextRowAnimationStart);

    return () => {
      window.removeEventListener('animationstart', handleNextRowAnimationStart);
    };
  }, []);

  // useEffect(() => {
  //   if (gameState.toAnimate !== null) {
  //     console.log("isStillAnimatingClue", gameState.toAnimate.isStillAnimatingClue);
  //   }
  // }, [gameState.toAnimate]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  })
  
  // useEffect(() => {
  //   if (gameState.needsToScrollTo === "bottom") {
  //     // setNeedsToScrollTo(null);
  //     setActiveGameStateValues({needsToScrollTo: null})
  //     scrollToBottom();
  //   }
  // }, [gameState.needsToScrollTo]);

  // useEffect(() => {
  //   if (gameState.isGivenUp) {
  //     scrollToBottom();
  //   }
  // }, [gameState.isGivenUp]);

  // useEffect(() => {
  //     scrollToBottom(); // FIX: doesn't handle backspace on an empty word.
  // }, [gameState.currentGuess, gameState.message, allGames.activeGamekey]);

  useEffect(() => {
    storeDailyGameSpecificState(activeGameState);
  }, [activeGameState]);

  const bottomElementRef = useRef(null);

  // shorter names
  const { gameParams, viewingId, toAnimate,
    actualWords,
    gameHistorySmall,
    currentGuess,
    message,
    emphasizedBoardNum,
    nextEmphasizedBoardNum,
    shareCopyStatus,
    postgameRows,
    isOverridingTurnLimit,
    isGivenUp,
  } = activeGameState;
  const {
    wordLength,
    numBoards,
    isDaily
  } = gameParams;

  // computed values
  const numTurns = gameHistorySmall.length;

  const isStillAnimatingClue = getIsStillAnimatingClue(toAnimate);
  const toAnimateNotNull = getFreshToAnimateIfNull(wordLength, numTurns, toAnimate);
  // console.log("getFreshToAnimateIfNull", wordLength, numTurns, toAnimate, toAnimateNotNull);
  // const toAnimatePastRows = toAnimateNotNull.pastRows;
  // const toAnimateActiveRow = toAnimateNotNull.activeRow;
  // console.log("givenUpRow", toAnimateNotNull.givenUpRow);
  const isStillAnimatingGivenUp = toAnimateNotNull.givenUpRow.isStillAnimatingGivenUp;

  // const isStillAnimatingRow = isStillAnimatingClue || isStillAnimatingGivenUp;
  const numTurnsReady = ((isStillAnimatingClue || isStillAnimatingGivenUp) ? numTurns - 1 : numTurns);
  const emphasizedBoardNumReady = ((isStillAnimatingClue || isStillAnimatingGivenUp || activeGameState.nextEmphasizedBoardNum === null) ? activeGameState.emphasizedBoardNum : activeGameState.nextEmphasizedBoardNum);
  const isTurnLimitEndingAfter = !isOverridingTurnLimit && numTurns >= TURN_LIMIT;
  const isTurnLimitEndingReady = !isOverridingTurnLimit && numTurnsReady >= TURN_LIMIT;
  const isWinByBoardBA = getIsWinByBoardBA(wordLength, numBoards, actualWords, gameHistorySmall);
  const isWinByBoardAfter = isWinByBoardBA.after;
  const isWinByBoardReady = isWinByBoardBA[isStillAnimatingClue ? "before" : "after"];
  const isWinReady = isWinByBoardReady.every((x) => (x));
  const isWinAfter = isWinByBoardBA.after.every((x) => (x));
  const isGivenUpReady = isGivenUp && !isStillAnimatingGivenUp;
  const isGameOverFinalAfter = isWinAfter || isGivenUp;
  const isGameOverFinalReady = isWinReady || isGivenUpReady;
  const isGameOverForNowAfter = isGameOverFinalAfter || isTurnLimitEndingAfter;
  const isGameOverForNowReady = isGameOverFinalReady || isTurnLimitEndingReady;
  
  const isNewGameAfter = (numTurns === 0);
  const canOverrideImportance = false;
  const importanceComponents = isWinByBoardReady.map((isWinOneBoardReady, boardNum) => {
    // return (checkWinOneBoard(pastGuesses) ? MINOR : MAJOR);
    return ((boardNum === emphasizedBoardNumReady || (emphasizedBoardNumReady === -1 && !isWinOneBoardReady)) ? MAJOR : MINOR);
  });
  // end computed values



  // const toAnimateNotNull = getFreshToAnimateIfNull(activeGameState.gameParams.wordLength, countNumTurnsByGameHistory(activeGameState.gameHistorySmall), activeGameState.toAnimate);
  // const isStillAnimatingClue = getIsStillAnimatingClue(activeGameState.toAnimate);
  // const beforeOrAfter = isStillAnimatingClue ? "before" : "after";
  // const boardToPastGuessesBA = getBoardToPastGuesses(activeGameState.gameParams.wordLength, activeGameState.gameParams.numBoards, activeGameState.actualWords, activeGameState.gameHistorySmall);
  // const boardToNetworkBA = getBoardToNetworkBA(activeGameState.gameParams.wordLength, activeGameState.gameParams.numBoards, activeGameState.actualWords, activeGameState.gameHistorySmall);
  // const keyboardAims = computeAllKeyboardAims(activeGameState.gameParams.wordLength, boardToPastGuessesBA[beforeOrAfter], maxLies);
  // const keyboardAims = boardToNetworkBA[beforeOrAfter].map(getKeyboardAimsOneBoardFromNetwork);
  // const importanceComponents = boardToPastGuessesBA.before.map((pastGuesses, boardNum) => {
  //   // return (checkWinOneBoard(pastGuesses) ? MINOR : MAJOR);
  //   return ((boardNum === activeGameState.emphasizedBoardNum || (activeGameState.emphasizedBoardNum === -1 && !    checkWinOneBoard(pastGuesses))) ? MAJOR : MINOR);
  // });
  
  // const isWin = checkWin(boardToPastGuessesBA.after);
  // const isNewGameAfter = checkNewGame(boardToPastGuessesBA.after);
  // const canOverrideImportance = false;

  // const moralData = getMoralDataIfIsMoral(activeGameState.actualWords, activeGameState.gameHistorySmall, activeGameState.postgameRows, boardToPastGuessesBA.after, activeGameState.isOverridingTurnLimit, activeGameState.isGivenUp, isWinAfter)

  let boardToNetworkForKeyboard = null;
  if (isGameOverFinalReady && activeGameState.postgameRows !== null && activeGameState.postgameRows.some(x => x)) {
    let selectedRowNum = null;
    activeGameState.postgameRows.forEach((isSelected, rowNum) => {
      if (isSelected) {
        selectedRowNum = rowNum;
      }
    })
    boardToNetworkForKeyboard = getBoardToNetworkBA(activeGameState.gameParams.wordLength, activeGameState.gameParams.numBoards, activeGameState.actualWords, activeGameState.gameHistorySmall.slice(0, selectedRowNum + 1)).before;
  } else {
    const boardToNetworkBA = getBoardToNetworkBA(activeGameState.gameParams.wordLength, activeGameState.gameParams.numBoards, activeGameState.actualWords, activeGameState.gameHistorySmall);
    const beforeOrAfter = isStillAnimatingClue ? "before" : "after";
    boardToNetworkForKeyboard = boardToNetworkBA[beforeOrAfter];
  }
  const keyboardAims = boardToNetworkForKeyboard.map(network => getKeyboardAimsOneBoardFromNetwork(network));
  
  if (activeGameState.gameParams.isDaily === false) {
    // console.log("actualWords", activeGameState.actualWords);
    // console.log(activeGameState);
  }
  // render
  return (
    <div className={"App" + (isDarkMode ? " dark-mode" : "") + (isHighContrast ? " high-contrast" : "")}>
      <div className="App-header">
        <div className="main-column" style={{maxWidth: Math.max(500, 50 * activeGameState.gameParams.wordLength * activeGameState.gameParams.numBoards) + "px",}}>
          <GameHeader maxLies={maxLies} setIsShowRules={setIsShowRulesWithStart} setIsShowSettings={setIsShowSettingsWithResets} />
          <div className="main-area">
            <DailySelector isDaily={activeGameState.gameParams.isDaily} dailyNum={activeGameState.gameParams.dailyNum} toggleIsDaily={toggleIsDaily} />
            {/* <GameArea 
              wordLength={activeGameState.gameParams.wordLength}
              keyboardAims={keyboardAims} 
              boardToPastGuesses={boardToPastGuessesBA.after} 
              gameHistorySmall={activeGameState.gameHistorySmall}
              currentGuess={activeGameState.currentGuess} 
              isGivenUp={activeGameState.isGivenUp} 
              givenUpWords={activeGameState.isGivenUp ? activeGameState.actualWords : null} 
              isWin={isWin} 
              isOverridingTurnLimit={activeGameState.isOverridingTurnLimit}
              message={activeGameState.message} 
              satisfaction={activeGameState.satisfaction} 
              emphasizedBoardNum={activeGameState.emphasizedBoardNum}
              doToggleBoardEmphasis={doToggleBoardEmphasis}
              toAnimate={toAnimateNotNull}
              moralData={moralData}
              postgameRows={activeGameState.postgameRows}
              doTogglePostgameRows={doTogglePostgameRows} 
              shareCopyStatus={activeGameState.shareCopyStatus} 
              doShare={doShare} 
              doNewGame={doNewPracticeGame} 
              isDaily={activeGameState.gameParams.isDaily} toggleIsDaily={toggleIsDaily} 
              doGiveUp={doGiveUp} setIsOverridingTurnLimit={setIsOverridingTurnLimit} /> */}
              {/* <div>
                <strong>The either/ordle moral</strong>
                <div style={{fontSize: "12pt", textAlign: "left"}}>
                  When you make a (wrong) guess, the computer gets to choose which word to clue. 
                  Tap on a row to learn about how much progress you would have made for each potential clue &mdash; the computer chooses the word that makes the least progress.
                </div>
                <button>Or, tap here to learn more about how progress is calculated.</button>
              </div> */}
              <GameAreaNEW gameState={activeGameState} 
                  // moralData={moralData}
                  {...{doToggleBoardEmphasis, doTogglePostgameRows, doGiveUp, 
                       setIsOverridingTurnLimit, doShare, doNewGame: doNewPracticeGame, toggleIsDaily,
                       doHandleClueAnimationEnd,
                       doHandleGivenUpAnimationEnd,
                       
                       emphasizedBoardNumReady,
                        isGameOverFinalReady,
                        toAnimateNotNull,
                        isStillAnimatingClue,
                        isGameOverForNowReady,
                        isWinByBoardAfter,
                        isWinByBoardReady,
                        numTurnsReady,
                        isWinReady,
                        numTurns,
                        isTurnLimitEndingReady,}} />
            {/* <div className="icon1">W</div> */}
            {/* <div className="icon2">
              <div className="icon2top"></div>
              <div className="icon2text"><div>o</div><div>r</div></div>
              <div className="icon2bottom"></div>
            </div> */}
            {/* <div className="icon3">
              <div className="icon3text"><div className="icon3o">o</div><div className="icon3r">r</div></div>
            </div> */}
            {/* <div className="icon2">
              <div className="icon3text"><div>o</div><div>r</div></div>
            </div> */}
            <div className="bottom-element" ref={bottomElementRef}></div>
          </div>
          {/* <KeyboardArea keyboardAims={keyboardAims} typeLetter={doTypeLetter} tryEnter={doTryEnter} typeBackspace={doTypeBackspace} canUndoWord={false} isShapeKeyboard={activeGameState.emphasizedBoardNum === -1 && isShapeKeyboard} isMiniKeyboard={isMiniKeyboard} setIsMiniKeyboardLS={setIsMiniKeyboardLS} importanceComponents={importanceComponents} canOverrideImportance={canOverrideImportance} keyboardAreaRef={keyboardAreaRef} /> */}
          {/* <KeyboardArea keyboardAims={keyboardAims} typeLetter={doTypeLetter} tryEnter={doTryEnter} typeBackspace={doTypeBackspace} canUndoWord={false} isShapeKeyboard={emphasizedBoardNumReady === -1 && isShapeKeyboard} isMiniKeyboard={isMiniKeyboard} setIsMiniKeyboardLS={setIsMiniKeyboardLS} importanceComponents={importanceComponents} canOverrideImportance={canOverrideImportance} keyboardAreaRef={keyboardAreaRef} /> */}
          <KeyboardArea keyboardAims={keyboardAims} typeLetter={doTypeLetter} tryEnter={doTryEnter} typeBackspace={doTypeBackspace} canUndoWord={false} isShapeKeyboard={(importanceComponents.every(x => x === MAJOR) || importanceComponents.every(x => x === MINOR)) && isShapeKeyboard} isMiniKeyboard={isMiniKeyboard} setIsMiniKeyboardLS={setIsMiniKeyboardLS} importanceComponents={importanceComponents} canOverrideImportance={canOverrideImportance} keyboardAreaRef={keyboardAreaRef} />
          {/* <div className="bottom-element" ref={(el) => {this.bottomElement = el;}}></div> */}
        </div>
      </div>
      {isShowRules ?
        <RulesModal
          isShowRules={isShowRules} setIsShowRules={setIsShowRulesWithStart}
        />
        :
        null
      }
      <SettingsModal
        doGiveUp={doGiveUp} doNewGame={doNewPracticeGame}
        isShowSettings={isShowSettings} setIsShowSettings={setIsShowSettingsWithResets}
        isDarkMode={isDarkMode} setIsDarkModeLS={setIsDarkModeLS} 
        isHighContrast={isHighContrast} setIsHighContrastLS={setIsHighContrastLS} 
        isShapeKeyboard={isShapeKeyboard} setIsShapeKeyboardLS={setIsShapeKeyboardLS} 
        maxLies={maxLies} maxLiesAdd={maxLiesAdd} maxLiesAddMessage={activeGameState.maxLiesAddMessage} isNewGame={isNewGameAfter}
      />
    </div>
  );
}

export default App;
