import { getDefault, localStorageGetAndSetDefault, localStorageGetDefault, localStorageSet } from './Storage.js';
import { getGameStateNewGame, getGameStateFromStored, getStoredFromGameState, getGamekeyStandalone } from './Mechanics.js'
import { START_DATE, STORED_GAME_RESET_ON_SWAP_ITEMS } from './Constants.js';


// loading stored game

export function storeDailyGameSpecificState(gameState) {
  if (gameState.gameParams.isDaily) {
    const gamekey = getGamekeyStandalone(gameState.gameParams);
    const gameSpecificState = getStoredFromGameState(gameState);
    const oldStoredGames = localStorageGetAndSetDefault("storedGames", {});
    const newStoredGames = {
      ...oldStoredGames,
      [gamekey]: gameSpecificState
    }
    // localStorageSet("storedDailyGame", gameSpecificState);
    localStorageSet("storedGames", newStoredGames);
  }
}

function getDailyNum() {
  const todaysDate = new Date();
  const dailyNum = Math.floor((todaysDate - START_DATE) / (1000 * 60 * 60 * 24));
  const queryParams = new URLSearchParams(window.location.search);
  const queryDay = queryParams.get('day');
  if (queryDay !== null && queryDay <= dailyNum) {
    return queryDay;
  }
  return dailyNum;
}

function getGameStateNewGameOrLoadDaily(gameParamsOptionalDailyNum) {
  // const dailyNum = (gameParams.isDaily ? getDailyNum() : null);
  // console.log("daily", (gameParamsOptionalDailyNum.dailyNum === null && gameParamsOptionalDailyNum.isDaily ? getDailyNum() : null));
  const newDailyNum = (gameParamsOptionalDailyNum.dailyNum === null && gameParamsOptionalDailyNum.isDaily ? getDailyNum() : null);
  // console.log("newDailyNum", newDailyNum);
  const gameParamsWithDailyNum = {
    ...gameParamsOptionalDailyNum,
    dailyNum: newDailyNum,
  }
  const gamekey = getGamekeyStandalone(gameParamsWithDailyNum);
  // console.log("gameParamsWithDailyNum", gameParamsWithDailyNum)
  
  // const storedDailyGame = (isDaily ? localStorageGetDefault("storedDailyGame", null) : null);
  // console.log("storedDailyGame", storedDailyGame);
  // console.log("stored actualWords")
  if (gameParamsWithDailyNum.isDaily) {
    const storedGames = localStorageGetDefault("storedGames", null);
    const storedDailyGame = (storedGames === null ? null : getDefault(storedGames, gamekey, null))
    // console.log("storedDailyGame", storedDailyGame);
    // console.log(storedDailyGame !== null)
    // console.log(storedDailyGame.gameParams.isDaily)
    // console.log(gameParamsWithDailyNum.dailyNum, storedDailyGame.gameParams.dailyNum)
    if (storedDailyGame !== null && storedDailyGame.gameParams.isDaily && gameParamsWithDailyNum.dailyNum === storedDailyGame.gameParams.dailyNum) {
      // console.log("Should load");
      return [gamekey, getGameStateFromStored(storedDailyGame)];
    }
  }
  // console.log("Didn't load");
  return [gamekey, getGameStateNewGame(gameParamsWithDailyNum)];

  // if (isDaily && dailyNum !== null && storedDailyGame !== null && storedDailyGame.isDaily && dailyNum === storedDailyGame.dailyNum) {
  //   return getGameStateFromStored(storedDailyGame)
  // } else {
  //   return getGameStateNewGame(isAbsurd, isDaily, dailyNum);
  // }
}

export function agGameStateNewGameOrLoadDaily(oldAllGames, gameParamsOptionalDailyNum) {
  // const newGamekey = getGamekeyStandalone(gameParamsOptionalDailyNum);
  const [newGamekey, newGameState] = getGameStateNewGameOrLoadDaily(gameParamsOptionalDailyNum);
  const newGameStates = {
    ...oldAllGames.gameStates,
    [newGamekey]: {...newGameState,
                   viewingId: oldAllGames.nextViewingId,},
  };
  const newAllGames = {
    activeGamekey: newGamekey,
    gameStates: newGameStates,
    nextViewingId: oldAllGames.nextViewingId + 1,
  };
  return newAllGames;
}

export function agSwapGames(oldAllGames, stateToChange) {
  const oldGameState = oldAllGames.gameStates[oldAllGames.activeGamekey];
  const oldGameParams = oldGameState.gameParams;
  const newGameParams = {
    ...oldGameParams,
    ...stateToChange,
  };
  // const isAbsurd = false; // need to update if implementing Absurd mode
  // // const newIsDaily = getDefault(stateToChange, "isDaily", state.gameParams.isDaily);
  // const newIsDaily = stateToChange["isDaily"];
  // const newGamekey = getGamekeyStandalone(newIsDaily);
  const newGamekey = getGamekeyStandalone(newGameParams);

  if (oldAllGames.activeGamekey === newGamekey) {
    console.log("Warning: Trying to swap out game for itself");
  }
  if (newGamekey in oldAllGames.gameStates) {
    // const newCurrentGameState = oldAllGames.gameStates[newGamekey];
    // const newGameStates = {...oldAllGames.gameStates,
    //                        [newGamekey]: newCurrentGameState};
    // const newAllGames = {
    //   activeGamekey: newGamekey,
    //   gameStates: newGameStates,
    // }
    return {
      activeGamekey: newGamekey,
      gameStates: {...oldAllGames.gameStates,
                   [newGamekey]: {...oldAllGames.gameStates[newGamekey],
                                  ...STORED_GAME_RESET_ON_SWAP_ITEMS,
                                  viewingId: oldAllGames.nextViewingId}},
      nextViewingId: oldAllGames.nextViewingId + 1,
    }
  } else {
    // return agGameStateNewGameOrLoadDaily(oldAllGames, newGamekey, isAbsurd, newIsDaily);
    return agGameStateNewGameOrLoadDaily(oldAllGames, newGameParams);
  }
}