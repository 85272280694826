import { GameboardRowNEW } from './Gameboard.js';
import { HIT, CLOSE, MISS, BLANK, UNEXPLORED, NEUTRAL, TYPING_ROW, CLUED_ROW, } from './Constants.js';
import { makeAllBlank, makeAllNeutral, makeAllSatisfied } from './Mechanics.js';

function ExplainerBoardOneFullRow({ children }) {
  return (
    <div className="gameboard-explainer">
      <div className="game-area">
        <div className="gameboard-block">
          <div className="gameboard-fullrow-wrapper">
            <div className="gameboard-fullrow">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ExplainerRow(props) {
  return (
    <div className="gameboard-row-wrapper">
      <GameboardRowNEW {...props} />
    </div>
  )
}

// class RulesModal extends React.Component {
//   constructor(props) {
//     super(props);
//     this.setIsShowRulesFalse = this.setIsShowRulesFalse.bind(this);
//   }
//   render() {
//     return (
//       <div className={"modal rules " + (this.props.isShowRules ? "show" : "hide")}>
//         <div className="shade" onClick={this.setIsShowRulesFalse} />
//         <div className="main-column">
//           <div className="modal-header">
//             <div className="modal-close-button" />
//             <div className="modal-title">How to Play</div>
//             <button className="modal-close-button" value="close-rules" onClick={this.setIsShowRulesFalse}>&times;</button>
//           </div>
//           <div className="modal-content">
//             <p>Guess the <strong>two</strong> secret words. After each guess, the computer will give you a clue about <strong>one</strong> of the secret words. Beware: The computer gets to choose which word to clue, and it does not like to be helpful.</p>
//             {/* <p>In <strong>either/ordle</strong>, you have to guess the secret words. Like <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer">Wordle</a> (by Josh Wardle) or the game show Lingo, you'll get feedback after each guess.
//             But you have two different words to guess, and after each guess, <strong>you only get a clue about one of the words</strong> (unlike <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer">Dordle</a>, by Guilherme S. Töws, which clues you about both words).
//             Beware, it's surprisingly evil! The computer gets to choose which of its secret words to clue you about, and the computer does not like to be helpful.</p> */}
//             {/* <p>Each clue gives you information about which letters are in the word, and in which position. For example,</p> */}
//             <p>Say you guess <em>CRANE</em>...</p>
//             <div className="gameboard-explainer">
//               <div className="game-area">
//                 <div className="gameboard-block">
//                   {[0, 1].map((index) => {
//                     return (
//                       <div key={index} className="gameboard">
//                         <GameboardRow keyboardAim={{c: [UNEXPLORED, UNEXPLORED], r: [UNEXPLORED, UNEXPLORED], a: [UNEXPLORED, UNEXPLORED], n: [UNEXPLORED, UNEXPLORED], e: [UNEXPLORED, UNEXPLORED]}} wordInfo={{guessedWord: "crane", pattern: ALL_BLANK}} lettersSatisfied={ALL_SATISFIED} isGivenUp={false} />
//                       </div>
//                     )
//                   })}
//                 </div>
//               </div>
//             </div>
//             <p>...and say you get this clue:</p>
//             <div className="gameboard-explainer">
//               <div className="game-area">
//                 <div className="gameboard-block">
//                   <div className="gameboard">
//                     <GameboardRow wordInfo={{guessedWord: "crane", pattern: [MISS, HIT, CLOSE, MISS, MISS].join('')}} lettersSatisfied={ALL_SATISFIED} />
//                   </div>
//                   <div className="gameboard">
//                     <GameboardRow wordInfo={{guessedWord: "", pattern: ALL_NEUTRAL}} lettersSatisfied={ALL_SATISFIED} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <div className="gameboard-explainer">
//               <GameboardRow wordInfo={{guessedWord: "crane", pattern: [MISS, HIT, CLOSE, MISS, MISS].join('')}} lettersSatisfied={ALL_SATISFIED} />
//             </div> */}
//             You learn:
//             <p>- <strong>R</strong> is the second letter of the left word.<br />
//             - <strong>A</strong> is in the left word somewhere.<br />
//             - <strong>C</strong>, <strong>N</strong>, and <strong>E</strong> are not in the left word.<br />
//             - You don't learn anything about the word on the right (except that the computer would rather not clue you about it).</p>
//             {/* (other than the fact that the computer would rather not give you a clue about it because it would have been even more helpful for you) */}
//             {/* <p><strong>If you get stuck: </strong>
//             No worries! You can click "Give Up" in the settings menu to see the secret words.</p> */}
//             <p>Okay, let's go!</p>
//             <div className="settings-row">
//               <button className="big-button" onClick={this.setIsShowRulesFalse}>Play Now!</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }

//   setIsShowRulesFalse() {
//     this.props.setIsShowRules(false);
//   }
// }

export function RulesModal(props) {
  function setIsShowRulesFalse() {
    props.setIsShowRules(false);
  }

  return (
    <div className={"modal rules " + (props.isShowRules ? "show" : "hide")}>
      <div className="shade" onClick={setIsShowRulesFalse} />
      <div className="main-column">
        <div className="modal-header">
          <div className="modal-close-button" />
          <div className="modal-title">How to Play</div>
          <button className="modal-close-button" value="close-rules" onClick={setIsShowRulesFalse}>&times;</button>
        </div>
        <div className="modal-content">
          <p>Guess the <strong>two</strong> secret words. After each guess, the computer will give you a clue about <strong>one</strong> of the secret words. 
          {/* If you correctly guess a secret word, the computer will tell you. But otherwise, beware: The computer gets to choose which word to clue, and it does not like to be helpful. It will clue the word that provides you the least progress. */}
          </p>
          {/* <p>In <strong>either/ordle</strong>, you have to guess the secret words. Like <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer">Wordle</a> (by Josh Wardle) or the game show Lingo, you'll get feedback after each guess.
          But you have two different words to guess, and after each guess, <strong>you only get a clue about one of the words</strong> (unlike <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer">Dordle</a>, by Guilherme S. Töws, which clues you about both words).
          Beware, it's surprisingly evil! The computer gets to choose which of its secret words to clue you about, and the computer does not like to be helpful.</p> */}
          {/* <p>Each clue gives you information about which letters are in the word, and in which position. For example,</p> */}
          <p>Say you guess <em>CRANE</em>...</p>
          <ExplainerBoardOneFullRow>
            {[0, 1].map((index) => {
              return (
                  <ExplainerRow key={index} wordLength={5} rowType={TYPING_ROW} word="crane" pattern={makeAllBlank(5)} network={null} lettersSatisfied={makeAllSatisfied(5)} onClick={null} isEmphasized={false} toAnimateOneRow={null} onLastFlipAnimationEnd={null} />
              )
            })}
          </ExplainerBoardOneFullRow>
          <p>...and say you get this clue:</p>
          <ExplainerBoardOneFullRow>
            <ExplainerRow wordLength={5} rowType={CLUED_ROW} word="crane" pattern={[MISS, HIT, CLOSE, MISS, MISS].join('')} network={null} lettersSatisfied={makeAllSatisfied(5)} onClick={null} isEmphasized={false} toAnimateOneRow={null} onLastFlipAnimationEnd={null} />
            <ExplainerRow wordLength={5} rowType={CLUED_ROW} word="" pattern={makeAllNeutral(5)} network={null} lettersSatisfied={makeAllSatisfied(5)} onClick={null} isEmphasized={false} toAnimateOneRow={null} onLastFlipAnimationEnd={null} />
          </ExplainerBoardOneFullRow>
          {/* <div className="gameboard-explainer">
            <GameboardRow wordInfo={{guessedWord: "crane", pattern: [MISS, HIT, CLOSE, MISS, MISS].join('')}} lettersSatisfied={ALL_SATISFIED} />
          </div> */}
          <p>You learn:</p>
          <p>- <strong>R</strong> is the second letter of the left word.<br />
          - <strong>A</strong> is in the left word somewhere.<br />
          - <strong>C</strong>, <strong>N</strong>, and <strong>E</strong> are not in the left word.<br />
          - You don't learn anything about the word on the right 
          (except that the computer would rather not clue you about it).
          {/* &nbsp;&mdash; except that you would have made even more progress from that clue. */}
          </p>
          {/* (other than the fact that the computer would rather not give you a clue about it because it would have been even more helpful for you) */}
          {/* <p><strong>If you get stuck: </strong>
          No worries! You can click "Give Up" in the settings menu to see the secret words.</p> */}
          <p><strong>Beware: the computer is your adversary.</strong> If you correctly guess a secret word, the computer will tell you. But otherwise, the computer gets to choose which word to clue, and it does not like to be helpful. It will clue the word that provides you the least progress.</p>
          <p>Okay, let's go!</p>
          <div className="settings-row">
            <button className="big-button" onClick={setIsShowRulesFalse}>Play Now!</button>
          </div>
        </div>
      </div>
    </div>
  )
}