import { getDefault, setDefault, setDefaultThenAdd } from './Storage.js';
import { TURN_LIMIT, MAX_LIES,
  HIT, CLOSE, MISS, BLANK, UNEXPLORED, UNTOUCHED, NEUTRAL,
  UNSHARED, SHARE_SUCCESS, SHARE_ERROR,
  LETTER_SATISFIED,
  ALL_ACTUAL, ALL_GUESSABLE, ALL_GUESSABLE_SET,
  STORED_GAME_SPECIFIC_KEYS, STORED_GAME_KEYS_NEEDING_COMPUTING, STORED_GAME_GENERIC_ITEMS, STORED_GAME_RESET_ON_SWAP_ITEMS,
  DARK_CONTRAST_AIM_TO_EMOJI,
  ALL_LETTERS, } from './Constants.js';
import { taTypeLetter, taBackspace, taSubmitGuess, taNoLongerAnimatingClue, getFreshToAnimateIfNull, getIsStillAnimatingClue, taGiveUp } from './Animate.js';
import { computeAllKeyboardAims } from './Keyboard.js';
import { applyConditionListToNetworkAsCopy, computeConditionValues, getAimFromNetwork } from './Moral.js';


// pure working with words

export function computePattern(actualWord, guessedWord) {
  const wordLength = actualWord.length;
  let scoreList = Array(wordLength).fill(MISS)
  let actualLetterCount = {}
  let guessLetterIndices = {}
  for (const i in guessedWord) {
    const a = actualWord[i];
    const g = guessedWord[i];
    if (a === g) {
      scoreList[i] = HIT
    } else {
      setDefault(guessLetterIndices, g, []);
      guessLetterIndices[g].push(i);
      setDefaultThenAdd(actualLetterCount, a, 0, 1);
    }
  }
  for (const a in actualLetterCount) {
    const count = actualLetterCount[a];
    for (const index of (guessLetterIndices[a] ?? []).slice(0, count)) {
      scoreList[index] = CLOSE
    }
  }
  return scoreList.join('');
}

function filterLiesLeftOnePattern(actualToLiesLeft, guessedWord, pattern) {
  const newActualToLiesLeft = {}
  for (const actualOption in actualToLiesLeft) {
    const numLeft = actualToLiesLeft[actualOption];
    const actualOptionPattern = computePattern(actualOption, guessedWord);
    if (actualOptionPattern === pattern) {
      newActualToLiesLeft[actualOption] = numLeft;
    } else {
      if (numLeft >= 1) {
        newActualToLiesLeft[actualOption] = numLeft - 1;
      }
    }
  }
  return newActualToLiesLeft;
}

function isAllHit(pattern) {
  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] !== HIT) {
      return false;
    }
  }
  return true;
}

export function isAllNeutral(pattern) {
  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] !== NEUTRAL) {
      return false;
    }
  }
  return true;
}

export function makeAllNeutral(wordLength) {
  return Array(wordLength).fill(NEUTRAL);
}

export function makeAllBlank(wordLength) {
  return Array(wordLength).fill(BLANK);
}

export function makeAllSatisfied(wordLength) {
  return Array(wordLength).fill(LETTER_SATISFIED).join('');
}

// working with pastGuesses

export function computeSatisfactionStandalone(currentGuess, pastGuesses) {
  return [0, null]; // NO LIES SO NOT RELEVANT
}

export function checkWinOneBoard(pastGuesses) {
  return (pastGuesses.length > 0) && isAllHit(pastGuesses[pastGuesses.length - 1].pattern);
}

export function checkWin(boardToPastGuesses) {
  for (let i = 0; i < boardToPastGuesses.length; i++) {
    if (!checkWinOneBoard(boardToPastGuesses[i])) {
      return false;
    }
  }
  return true;
}

function checkNewGameOneBoard(pastGuesses) {
  return (pastGuesses.length === 0);
}

export function checkNewGame(boardToPastGuesses) {
  let isNewGame = true;
  for (let boardNum = 0; boardNum < boardToPastGuesses.length; boardNum++) {
    const pastGuesses = boardToPastGuesses[boardNum];
    isNewGame = isNewGame && checkNewGameOneBoard(pastGuesses);
  }
  return isNewGame;
}

// computer player decisions

export function getLiesLeftToCountAndCumulativeProgress(actualToLiesLeft) {
  const liesLeftToCount = countLiesLeft(actualToLiesLeft);
  const numTurns = estimateTurnsLeft(liesLeftToCount);
  const keys = Object.keys(actualToLiesLeft);
  let cumulativeProgress
  if (keys.length === 0) {
    cumulativeProgress = Infinity;
  } else {
    const wordLength = keys[0].length; // not proud of this strategy for getting word length
    const initial = Math.sqrt(Math.log2(ALL_ACTUAL[wordLength].length));
    cumulativeProgress = (initial - Math.sqrt(numTurns)) / initial;
  }
  return [liesLeftToCount, cumulativeProgress];
} // TODO: precalculate some constants

function chooseBoardMaximizeTurns(wordLength, numBoards, actualWords, gameHistorySmall, guessedWord) {
  const hypGameHistorySmall = [...gameHistorySmall, {currentGuess: guessedWord, chosenBoardNum: null}];
  const hypBoardToActualToLiesLeftBA = getBoardToActualToLiesLeft(wordLength, numBoards, actualWords, hypGameHistorySmall);
  // const hypBoardToActualToLiesLeft = hypBoardToActualToLiesLeftBA.true;
  const hypIsWinByBoardBA = getIsWinByBoardBA(wordLength, numBoards, actualWords, hypGameHistorySmall);
  let bestBoardNum = null;
  let bestProgress = null;
  hypIsWinByBoardBA.before.forEach((isWinBefore, boardNum) => {
    if (!isWinBefore) {
      if (hypIsWinByBoardBA.true[boardNum]) {
        bestBoardNum = boardNum;
        bestProgress = -1;
      } else {
        const [liesLeftToCountBefore, cumulativeProgressBefore] = getLiesLeftToCountAndCumulativeProgress(hypBoardToActualToLiesLeftBA.before[boardNum]);
        const [liesLeftToCountHyp, cumulativeProgressHyp] = getLiesLeftToCountAndCumulativeProgress(hypBoardToActualToLiesLeftBA.true[boardNum]);
        console.log("Considering board", boardNum, liesLeftToCountBefore, liesLeftToCountHyp);
        const progress = cumulativeProgressHyp - cumulativeProgressBefore;
        if (bestProgress === null || progress < bestProgress) {
          bestBoardNum = boardNum;
          bestProgress = progress;
        }
      }
    }
  })

  const bestPattern = computePattern(actualWords[bestBoardNum], guessedWord);
  return [bestBoardNum, bestPattern];
}

function countLiesLeft(actualToLiesLeft) {
  const liesLeftToCount = {};
  for (const actualOption in actualToLiesLeft) {
    const liesLeft = actualToLiesLeft[actualOption];
    setDefaultThenAdd(liesLeftToCount, liesLeft, 0, 1);
  }
  return liesLeftToCount
}

function estimateTurnsLeft(liesLeftToCount) {
  let totalWords = 0;
  for (const numLies in liesLeftToCount) {
    totalWords += liesLeftToCount[numLies];
  }
  return Math.log2(totalWords);
}

// function choosePatternMaximizeTurns(actualToLiesLeft, guessedWord) {
//   const patternToLiesLeftToCount = computePatternToLiesLeftToCount(actualToLiesLeft, guessedWord);
//   let bestEstTurnsLeft = -1;
//   let bestPattern = null;
//   const logOptions = {}
//   for (const pattern in patternToLiesLeftToCount) {
//     const liesLeftToCount = patternToLiesLeftToCount[pattern];
//     let estTurnsLeft;
//     if (pattern === ALL_HIT) {
//       estTurnsLeft = 0;
//     } else {
//       estTurnsLeft = estimateTurnsLeft(liesLeftToCount);
//     }
//     logOptions[pattern]  = [estTurnsLeft, liesLeftToCount];
//     if (estTurnsLeft > bestEstTurnsLeft) {
//       bestEstTurnsLeft = estTurnsLeft;
//       bestPattern = pattern;
//     }
//   }
//   console.log("Choosing pattern", logOptions);
//   return bestPattern;
// }

// working with gameState

export function agUpdateFnFromGameStateFn(updateFunction) {
  return oldAllGames => {
    const oldGameState = oldAllGames.gameStates[oldAllGames.activeGamekey];
    const newGameState = updateFunction(oldGameState);
    return {
      activeGamekey: oldAllGames.activeGamekey,
      gameStates: {
        ...oldAllGames.gameStates,
        [oldAllGames.activeGamekey]: newGameState,
      },
      nextViewingId: oldAllGames.nextViewingId,
    }
  }
}

export function gsUpdateFnFromGameStateValues(newValues) {
  return oldGameState => {
    return {
      ...oldGameState,
      ...newValues,
    }
  }
}

export function getGamekeyStandalone(gameParams) {
  // return isDaily;
  return [gameParams.wordLength, gameParams.numBoards, gameParams.isDaily, gameParams.isAbsurd];
}

export function agNewPracticeGame(oldAllGames) {
  const oldGameState = oldAllGames.gameStates[oldAllGames.activeGamekey];
  const oldGameParams = oldGameState.gameParams;
  // const newIsDaily = false;
  const newGameParams = {
    ...oldGameParams,
    isDaily: false,
    dailyNum: null,
  }
  const newGamekey = getGamekeyStandalone(newGameParams);
  const newGameState = getGameStateNewGame(newGameParams);
  // const newGameState = getGameStateFromStored(EXAMPLE_STORED_DAILY_GAME);
  return {
    activeGamekey: newGamekey,
    gameStates: {
      ...oldAllGames.gameStates,
      [newGamekey]: {...newGameState,
                     viewingId: oldAllGames.nextViewingId,},
    },
    nextViewingId: oldAllGames.nextViewingId + 1,
  }
}

function hash(n) {
  // https://en.wikipedia.org/wiki/Lehmer_random_number_generator
  // Misha uses this
  const m = 2147483647;
  const a = 48271;
  return (a * n) % m;
}

function generateActualWords(gameParams) {
  // console.log("generateActualWords");
  const numWordsAllActual = ALL_ACTUAL[gameParams.wordLength].length;
  let actualWords = null;
  if (!gameParams.isAbsurd) {
    actualWords = Array(gameParams.numBoards);
    const actualIndices = Array(gameParams.numBoards);
    if (gameParams.isDaily) {
      let seed = gameParams.dailyNum;
      seed = hash(seed);
      seed += gameParams.numBoards;
      seed = hash(seed);
      for (let boardNum = 0; boardNum < gameParams.numBoards; boardNum++) {
        seed = hash(seed)
        actualIndices[boardNum] = seed % numWordsAllActual;
      }
    } else {
      for (let boardNum = 0; boardNum < gameParams.numBoards; boardNum++) {
        actualIndices[boardNum] = Math.floor(Math.random() * numWordsAllActual);
      }
    }
    actualIndices.forEach((actualIndex, boardNum) => {
      actualWords[boardNum] = ALL_ACTUAL[gameParams.wordLength][actualIndex];
    })
    // actualWords = ["crane", "stare"];
    // actualWords = ["labor"];
  }
  return actualWords;
}

export function getGameStateNewGame(gameParams) {
  // console.log("getGameStateNewGame");
  const actualWords = generateActualWords(gameParams);
  // const boardToPastGuesses = Array(gameParams.numBoards);
  // for (let i = 0; i < gameParams.numBoards; i++) {
  //   boardToPastGuesses[i] = [];
  // }
  const gameSpecificState = {
    ...STORED_GAME_GENERIC_ITEMS,
    ...STORED_GAME_RESET_ON_SWAP_ITEMS,
    // isDaily: isDaily,
    // dailyNum: dailyNum,
    gameParams: gameParams,
    // boardToPastGuesses: boardToPastGuesses, 
    gameHistorySmall: [],
    // pastRelativeTrust: [], 
    // relativeToAbsoluteTrust: this.computeRelativeToAbsoluteTrust(isFancyTrust, [], maxLies),
    actualWords: actualWords, 
    // actualWord: actualWord, 
    isOverridingTurnLimit: false,
    isGivenUp: false,
    // needsToScrollTo: "top",
  }

  return gameSpecificState;
}

const EXAMPLE_STORED_DAILY_GAME = {
  gameParams: {
    wordLength: 5,
    numBoards: 2,
    isDaily: false,
    dailyNum: null,
    isAbsurd: false,
  },
  // isDaily: false,
  // dailyNum: null,
  isGivenUp: false,
  isOverridingTurnLimit: true,
  gameHistorySmall: [
    {currentGuess: "mamma", chosenBoardNum: 0},
    {currentGuess: "stink", chosenBoardNum: 1},
    {currentGuess: "inset", chosenBoardNum: 1},
    {currentGuess: "rabbi", chosenBoardNum: 1},
    {currentGuess: "sippy", chosenBoardNum: 1},
    {currentGuess: "fudgy", chosenBoardNum: 0},
    {currentGuess: "digit", chosenBoardNum: 0},
    {currentGuess: "tease", chosenBoardNum: 1},
    {currentGuess: "thugs", chosenBoardNum: 1},
    {currentGuess: "attic", chosenBoardNum: 0},
    {currentGuess: "folks", chosenBoardNum: 0},
    {currentGuess: "llama", chosenBoardNum: 0},
    {currentGuess: "where", chosenBoardNum: 0},
    {currentGuess: "stile", chosenBoardNum: 0},
    {currentGuess: "still", chosenBoardNum: 0},
    {currentGuess: "folio", chosenBoardNum: 1},
    {currentGuess: "ovoid", chosenBoardNum: 1},
    {currentGuess: "ready", chosenBoardNum: 1},
    {currentGuess: "widow", chosenBoardNum: 1},
  ],
  actualWords: ["still", "widow"],
};

const IS_VERBOSE_MEMOIZE = false;

export function memoizeByGameHistory(givenValueName, getInitialValue, applyGuessFalse, applyGuessTrue) {
  // applyGuess(wordLength, actualWordOneWord, currentGuess, valueBefore)
  // getInitialValue(wordLength, numBoards)
  let valueName = givenValueName;
  let savedWordLength = null;
  let savedNumBoards = null;
  let savedActualWords = null;
  let savedGameHistorySmall = [];
  let savedInitialValues = null;
  let savedValues = [];

  function getValues(wordLength, numBoards, actualWords, gameHistorySmall) {
    if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: starting getValues for " + valueName);
    let isSameBasics = (wordLength === savedWordLength) && (numBoards === savedNumBoards);
    if (isSameBasics) {
      actualWords.forEach((word, i) => {
        isSameBasics &= (word === savedActualWords[i])
      });
    }

    if (!isSameBasics) {
      savedWordLength = wordLength;
      savedNumBoards = numBoards;
      savedActualWords = actualWords;
      savedGameHistorySmall = [];
      savedInitialValues = null;
      savedValues = [];
    }

    if (savedInitialValues === null) {
      savedInitialValues = Array(numBoards);
      for (let boardNum = 0; boardNum < numBoards; boardNum++) {
        savedInitialValues[boardNum] = getInitialValue(wordLength, numBoards);
      }
      if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: set savedInitialValues for " + valueName + ", which are:", savedInitialValues);
    }

    if (gameHistorySmall.length === 0) {
      if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: gameHistorySmall has length 0, so returning savedInitialValues for " + valueName + ", which are:", savedInitialValues);
      return {
        before: savedInitialValues, // REEVALUATE whether it makes sense to set before, false and true here.
        false: savedInitialValues,
        true: savedInitialValues,
        after: savedInitialValues,
      };
    }

    let runningValues = savedInitialValues;

    gameHistorySmall.forEach((pastGuessSmall, turnNum) => {
      if (turnNum < savedGameHistorySmall.length && (savedGameHistorySmall[turnNum].currentGuess !== gameHistorySmall[turnNum].currentGuess || savedGameHistorySmall[turnNum].chosenBoardNum !== gameHistorySmall[turnNum].chosenBoardNum)) {
        savedGameHistorySmall = savedGameHistorySmall.slice(0, turnNum);
        savedValues = savedValues.slice(0, turnNum);
      }

      if (turnNum < savedGameHistorySmall.length) {
        runningValues = savedValues[turnNum].after;
      } else {
        if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: Computing", valueName, "for pastGuessSmall", pastGuessSmall, "on turn", turnNum);
        // let newValuesBefore;
        // if (turnNum === 0) {
        //   newValuesBefore = Array(numBoards);
        //   for (let boardNum = 0; boardNum < numBoards; boardNum++) {
        //     newValuesBefore[boardNum] = getInitialValue(wordLength, numBoards);
        //   }
        // } else {
        //   newValuesBefore = runningValues;
        // }
        const valuesBefore = runningValues;
        
        const savedValuesOneTurn = {
          before: valuesBefore,
          false: Array(numBoards),
          true: Array(numBoards),
          after: Array(numBoards),
        }

        for (let boardNum = 0; boardNum < numBoards; boardNum++) {
          savedValuesOneTurn.false[boardNum] = applyGuessFalse(wordLength, actualWords[boardNum], pastGuessSmall.currentGuess, valuesBefore[boardNum]);
          savedValuesOneTurn.true[boardNum] = applyGuessTrue(wordLength, actualWords[boardNum], pastGuessSmall.currentGuess, valuesBefore[boardNum]);
          savedValuesOneTurn.after[boardNum] = savedValuesOneTurn[boardNum === pastGuessSmall.chosenBoardNum][boardNum];
        }

        if (savedValues.length !== turnNum) {
          console.error("memoizeByGameHistory getValue: expected savedValues to have length turnNum");
        }
        if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: Computed", valueName, "for pastGuessSmall", pastGuessSmall, "on turn", turnNum, "and computed value is:", savedValuesOneTurn);
        savedGameHistorySmall.push(pastGuessSmall);
        savedValues.push(savedValuesOneTurn);
        runningValues = savedValuesOneTurn.after;
      }
    });

    if (IS_VERBOSE_MEMOIZE) console.log("memoizeByGameHistory: savedValues for", valueName, "are now", savedValues);
    return savedValues[gameHistorySmall.length - 1]; // not savedGameHistorySmall
  }

  return getValues;
}

function applyGuessIdentity(wordLength, actualWord, currentGuess, valueBefore) {
  return valueBefore;
}

function applyGuessTrueActualToLiesLeft(wordLength, actualWord, currentGuess, actualToLiesLeftBefore) {
  const pattern = computePattern(actualWord, currentGuess);
  return filterLiesLeftOnePattern(actualToLiesLeftBefore, currentGuess, pattern);
}

function getFreshActualToLiesLeft(wordLength) {
  const actualToLiesLeft = {};
  for (const actualOption of ALL_ACTUAL[wordLength]) {
    actualToLiesLeft[actualOption] = MAX_LIES;
  }
  return actualToLiesLeft
}

export const getBoardToActualToLiesLeft = memoizeByGameHistory("actualToLiesLeft", getFreshActualToLiesLeft, applyGuessIdentity, applyGuessTrueActualToLiesLeft);



function getFreshPastGuesses(wordLength) {
  return [];
}

function checkWinOneBoardByPastGuesses(actualWord, pastGuesses) {
  if (pastGuesses === null || pastGuesses.length === 0) {
    return false;
  }
  if (pastGuesses[pastGuesses.length - 1].guessedWord === actualWord) {
    return true;
  }
  return false;
}

function applyGuessFalsePastGuesses(wordLength, actualWord, currentGuess, pastGuessesBefore) {
  let pastGuessesAfter;
  if (checkWinOneBoardByPastGuesses(actualWord, pastGuessesBefore)) {
    return pastGuessesBefore;
  } else {
    pastGuessesAfter = [...pastGuessesBefore, {guessedWord: "", pattern: makeAllNeutral(wordLength)}];
    return pastGuessesAfter;
  }
}

function applyGuessTruePastGuesses(wordLength, actualWord, currentGuess, pastGuessesBefore) {
  let pastGuessesAfter;
  if (checkWinOneBoardByPastGuesses(actualWord, pastGuessesBefore)) {
    return pastGuessesBefore;
  } else {
    const pattern = computePattern(actualWord, currentGuess);
    pastGuessesAfter = [...pastGuessesBefore, {guessedWord: currentGuess, pattern: pattern}];
    return pastGuessesAfter;
  }
}

const getBoardToPastGuesses = memoizeByGameHistory("pastGuesses", getFreshPastGuesses, applyGuessFalsePastGuesses, applyGuessTruePastGuesses);



// temporary export
export function getFreshNetwork(wordLength) {
  const network = {};
  network['*'] = {};
  for (const i in ALL_LETTERS) {
    const letter = ALL_LETTERS[i];
    network['*'][letter] = {minCapacity: 0, maxCapacity: wordLength};
    network[letter] = {};
    for (let position = 0; position < wordLength; position++) {
      network[letter][position] = {minCapacity: 0, maxCapacity: 1}; // position gets converted to a string when used as a key
    }
  }

  return network;
}

function applyGuessTrueNetwork(wordLength, actualWord, currentGuess, networkBefore) {
  const valuedConditions = computeConditionValues(actualWord, currentGuess);
  const networkAfter = applyConditionListToNetworkAsCopy(networkBefore, valuedConditions);
  return networkAfter;
}

export const getBoardToNetworkBA = memoizeByGameHistory("network", getFreshNetwork, applyGuessIdentity, applyGuessTrueNetwork);


export function getKeyboardAimsOneBoardFromNetwork(network) {
  const keyboardAims = {};
  ALL_LETTERS.forEach(letter => {
    const aim = getAimFromNetwork(network, letter)
    keyboardAims[letter] = [aim, aim];
  });
  return keyboardAims;
}


// temporary export
export function getFreshFlow(wordLength) {
  const flow = {};
  flow['*'] = {};
  for (const i in ALL_LETTERS) {
    const letter = ALL_LETTERS[i];
    flow['*'][letter] = 0;
    flow[letter] = {};
    for (let position = 0; position < wordLength; position++) {
      flow[letter][position] = 0; // position gets converted to a string when used as a key
    }
  }
  for (let position = 0; position < wordLength; position++){
    const letter = ALL_LETTERS[position % ALL_LETTERS.length];
    flow['*'][letter] += 1;
    flow[letter][position] += 1;
  }

  return flow;
}





function applyGuessTrueIsWinOneBoard(wordLength, actualWord, currentGuess, isWinOneBoardBefore) {
  return isWinOneBoardBefore || (actualWord === currentGuess);
}

// wordLength isn't used
export const getIsWinByBoardBA = memoizeByGameHistory("isWinByBoard", () => false, applyGuessIdentity, applyGuessTrueIsWinOneBoard);

function checkWinByGameHistory(wordLength, numBoards, actualWords, gameHistorySmall) {
  return getIsWinByBoardBA(wordLength, numBoards, actualWords, gameHistorySmall).after.every((x) => (x));
}



export function getGameStateFromStored(storedDailyGame) {
  const gameState = {...STORED_GAME_GENERIC_ITEMS, ...STORED_GAME_RESET_ON_SWAP_ITEMS};
  for (const gameSpecificKey of STORED_GAME_SPECIFIC_KEYS) {
    gameState[gameSpecificKey] = storedDailyGame[gameSpecificKey];
  }
  return gameState;
}

export function getStoredFromGameState(gameState) {
  const gameSpecificState = {};
  for (const gameSpecificKey of STORED_GAME_SPECIFIC_KEYS) {
    // console.log("storing key", gameSpecificKey, gameState[gameSpecificKey]);
    gameSpecificState[gameSpecificKey] = gameState[gameSpecificKey];
  }
  return gameSpecificState;
}

export function gsGiveUp(oldGameState) {
  const isWin = checkWinByGameHistory(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall);
  const isGivenUp = oldGameState.isGivenUp;
  if (isWin || isGivenUp) {
    return {
      ...oldGameState,
      isShowSettings: false,
      shareCopyStatus: UNSHARED,
      // needsToScrollTo: "bottom",
    };
  } else {
    const actualSatisfaction = null;
    const numGuesses = countNumTurnsByGameHistory(oldGameState.gameHistorySmall);
    const newToAnimate = taGiveUp(oldGameState.toAnimate, oldGameState.gameParams.wordLength, numGuesses);
    return {
      ...oldGameState,
      isShowSettings: false,
      message: 'You gave up after ' + countNumTurnsByGameHistory(oldGameState.gameHistorySmall) + '/' + TURN_LIMIT + ' turns.',
      maxLiesAddMessage: '',
      satisfaction: actualSatisfaction,
      nextEmphasizedBoardNum: -1,
      shareCopyStatus: UNSHARED,
      // currentGuess: bestActual,
      currentGuess: "",
      isGivenUp: true,
      // typedLettersToAnimate: null,
      // isAnimateNextRow: false,
      // needsToScrollTo: "bottom",
      toAnimate: newToAnimate,
    };
  }
}

function getGameboardEmoji(boardToPastGuesses, wordLength, isWin, isDarkMode, isHighContrast) {
  // const isWin = checkWinStandalone(boardToPastGuesses);
  // const actualWord = (isWin ? pastGuesses[pastGuesses.length - 1].guessedWord : null);
  // const [/*numUnsatisfied*/, satisfaction] = (isWin ? computeSatisfactionStandalone(actualWord, pastGuesses) : [null, null]);
  const aimToEmoji = DARK_CONTRAST_AIM_TO_EMOJI[isDarkMode][isHighContrast];
  let s = ''
  const numBoards = boardToPastGuesses.length;
  const numTurns = countNumTurns(boardToPastGuesses);
  for (let turnNum = 0; turnNum < numTurns; turnNum++) {
    const row = Array(numBoards);
    boardToPastGuesses.forEach((pastGuesses, boardNum) => {
      // const isLie = (satisfaction !== null && satisfaction[index] !== ALL_SATISFIED);
      const pastGuessPattern = (turnNum < pastGuesses.length ? pastGuesses[turnNum].pattern : makeAllNeutral(wordLength));
      let sOne = '';
      [...pastGuessPattern].forEach((aim) => {
        sOne += aimToEmoji[aim];
      })
      // if (isLie) {
      //   s += " 🤥 LIE"
      // }
      // if (pastGuess.pattern === ALL_HIT) {
      //   s += "\n🏆 " + pastGuess.guessedWord.toUpperCase() + " 🏆";
      // }
      row[boardNum] = sOne;
    });
    s += row.join(' ') + '\n';
  }
  if (!isWin) {
    const row = Array(numBoards);
    boardToPastGuesses.forEach((pastGuesses, boardNum) => {
      if (checkWinOneBoard(pastGuesses)) {
        row[boardNum] = Array(wordLength).fill(aimToEmoji[NEUTRAL]).join('');
      } else {
        row[boardNum] = Array(wordLength).fill(aimToEmoji[BLANK]).join('');
      }
    });
    s += row.join(' ') + " Gave Up\n";
  }
  return s;
}

export function getShareText(gameState, isWin, isDarkMode, isHighContrast) {
  // const boardToPastGuesses = gameState.boardToPastGuesses; 
  const boardToPastGuesses = getBoardToPastGuesses(gameState.gameParams.wordLength, gameState.gameParams.numBoards, gameState.actualWords, gameState.gameHistorySmall).after;
  const gameBoardEmoji = getGameboardEmoji(boardToPastGuesses, gameState.gameParams.wordLength, isWin, isDarkMode, isHighContrast); 
  const boardToNumTurnsString = Array(gameState.gameParams.numBoards);
  boardToPastGuesses.forEach((pastGuesses, boardNum) => {
    if (checkWinOneBoard(pastGuesses)) {
      boardToNumTurnsString[boardNum] = "" + pastGuesses.length;
    } else {
      boardToNumTurnsString[boardNum] = "X";
    }
  });
  const dayString = (gameState.gameParams.isDaily ? "(day " + gameState.gameParams.dailyNum + ")" : "(practice)");
  let text = "either/ordle " + dayString + " " + boardToNumTurnsString.join('&') + "/" + TURN_LIMIT + "\n" //"/\u221e\n";
  text += '\n';
  text += gameBoardEmoji;
  text += '\n';
  text += "#eitherordle";

  return text
}

export function gsTypeLetter(oldGameState, letter) {
  // console.log("TYPE LETTER");
  const currentGuess = oldGameState.currentGuess;
  // const isWin = checkWinByGameHistory(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall);
  // const oldBoardToPastGuesses = getBoardToPastGuesses(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall).after;
  // const [ , , , isGameOverForNow] = checkGameStatusStandalone(oldBoardToPastGuesses, oldGameState.isOverridingTurnLimit, oldGameState.isGivenUp, isWin);
  // TODO: this calculation of isGameOverForNowAfter is redundant with that in App; try to fix this
  const numTurns = oldGameState.gameHistorySmall.length;
  const isGivenUp = oldGameState.isGivenUp;
  const isOverridingTurnLimit = oldGameState.isOverridingTurnLimit;
  const isWinByBoardBA = getIsWinByBoardBA(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall);
  const isWinAfter = isWinByBoardBA.after.every((x) => (x));
  const isTurnLimitEndingAfter = !isOverridingTurnLimit && numTurns >= TURN_LIMIT;
  const isGameOverFinalAfter = isWinAfter || isGivenUp;
  const isGameOverForNowAfter = isGameOverFinalAfter || isTurnLimitEndingAfter;
  const isGameOverForNow = isGameOverForNowAfter;
  if (currentGuess.length < oldGameState.gameParams.wordLength && !isGameOverForNow) {
    // console.log("oldGameState", oldGameState);
    // const numGuesses = getNumGuesses(oldGameState);
    const numGuesses = countNumTurnsByGameHistory(oldGameState.gameHistorySmall);
    // const oldToAnimateNotNull = getFreshToAnimateIfNull(oldGameState.gameParams.wordLength, numGuesses, oldGameState.toAnimate);
    const newToAnimate = taTypeLetter(oldGameState.toAnimate, oldGameState.gameParams.wordLength, numGuesses, currentGuess.length);
    return {
      ...oldGameState,
      currentGuess: currentGuess + letter,
      toAnimate: newToAnimate,
      // needsToScrollTo: "bottom",
    };
  } else {
    return oldGameState;
  }
}

// export function gsNoLongerAnimatingClue(oldGameState) {
//   return {
//     ...oldGameState,
//     toAnimate: taNoLongerAnimatingClue(oldGameState.toAnimate),
//   }
// }

export function gsTryEnter(oldGameState) {
  const wordLength = oldGameState.gameParams.wordLength;
  const numBoards = oldGameState.gameParams.numBoards;
  const currentGuess = oldGameState.currentGuess;
  if (currentGuess.length === wordLength && !oldGameState.isGivenUp) {
    if (ALL_GUESSABLE_SET[wordLength].has(currentGuess)) {
      // const boardToIsAlreadyWon = Array(numBoards);
      // const oldBoardToPastGuesses = getBoardToPastGuesses(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall).after;
      // oldBoardToPastGuesses.forEach((pastGuesses, boardNum) => {
      //   boardToIsAlreadyWon[boardNum] = checkWinOneBoard(pastGuesses);
      // });
      // const boardToIsAlreadyWon = isWinByBoardAfter;
      let chosenBoardNum;
      let pattern;
      // let patterns = Array(numBoards);
      const [satisfiesHardMode, satisfaction, message] = [true, null, null]; // Bypass the idea of hard mode - doesn't make sense for either/ordle
      if (satisfiesHardMode) {
        if (oldGameState.isAbsurd) {
          console.log("not implemented");
        } else {
          [chosenBoardNum, pattern] = chooseBoardMaximizeTurns(wordLength, numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall, currentGuess);
        }
        const newGameHistorySmall = [...oldGameState.gameHistorySmall, {currentGuess: currentGuess, chosenBoardNum: chosenBoardNum}];
        
        const newIsWin = checkWinByGameHistory(wordLength, numBoards, oldGameState.actualWords, newGameHistorySmall);
        const newSatisfaction = null; // no concept of satisfaction in because no hard mode
        const emphasizedBoardNumAfterPrevious = (oldGameState.nextEmphasizedBoardNum === null ? oldGameState.emphasizedBoardNum : oldGameState.nextEmphasizedBoardNum);
        // let emphasizedBoardNum = oldGameState.emphasizedBoardNum;
        let emphasizedBoardNum = emphasizedBoardNumAfterPrevious;
        let nextEmphasizedBoardNum = null;
        if (newIsWin) {
          nextEmphasizedBoardNum = -1;
        } else if (isAllHit(pattern)) {
          let numBoardsLeft = 0;
          let boardNumNotWin = null;
          const isWinByBoard = getIsWinByBoardBA(wordLength, numBoards, oldGameState.actualWords, newGameHistorySmall);
          if (emphasizedBoardNumAfterPrevious === chosenBoardNum && emphasizedBoardNumAfterPrevious !== -1 && isWinByBoard.after[emphasizedBoardNumAfterPrevious]) {
            nextEmphasizedBoardNum = -1;
          }
          if (false) {
            isWinByBoard.after.forEach((isWinOneBoard, boardNum) => {
              if (!isWinOneBoard) {
                numBoardsLeft++;
                boardNumNotWin = boardNum;
              }
            });
            if (numBoardsLeft === 1) {
              nextEmphasizedBoardNum = boardNumNotWin;
            }
          }
        }

        // const numGuesses = getNumGuesses(oldGameState);
        const numGuesses = countNumTurnsByGameHistory(oldGameState.gameHistorySmall);
        // const oldToAnimateNotNull = getFreshToAnimateIfNull(oldGameState.gameParams.wordLength, numGuesses, oldGameState.toAnimate);
        // const wordInfo = {
        //   guessedWord: currentGuess,
        //   pattern: makeAllBlank(wordLength),
        // }
        // const keyboardAims = computeAllKeyboardAims(oldGameState.gameParams.wordLength, oldBoardToPastGuesses, MAX_LIES);
        // const newToAnimate = taSubmitGuess(oldToAnimateNotNull, oldGameState, wordInfo); // wordLength, wordInfo, keyboardAims);
        const newToAnimate = taSubmitGuess(oldGameState.toAnimate, wordLength, numGuesses);
        // const oldCluesToAnimate = (oldGameState.cluesToAnimate === null) ? Array(oldGameState.gameHistorySmall.length).fill(false) : oldGameState.cluesToAnimate;
        return {...oldGameState,
                gameHistorySmall: newGameHistorySmall,
                currentGuess: '',
                message: '',
                maxLiesAddMessage: '',
                satisfaction: newSatisfaction,
                shareCopyStatus: UNSHARED,
                isGivenUp: false,
                // needsToScrollTo: "bottom",
                emphasizedBoardNum: emphasizedBoardNum,
                nextEmphasizedBoardNum: nextEmphasizedBoardNum,
                toAnimate: newToAnimate,
                // cluesToAnimate: [...oldCluesToAnimate, true],
                // isAnimateNextRow: true,
              };
      } else { // not satisfiesHardMode
        return {
          ...oldGameState,
          message: message,
          maxLiesAddMessage: '',
          satisfaction: satisfaction,
          shareCopyStatus: UNSHARED,
          isGivenUp: false,
          // needsToScrollTo: "bottom",
        };
      }
    } else { // not in word list
      return {
        ...oldGameState,
        message: 'Not in word list',
        maxLiesAddMessage: '',
        satisfaction: null,
        shareCopyStatus: UNSHARED,
        isGivenUp: false,
        // needsToScrollTo: "bottom",
      };
    }
  } else {
    return oldGameState;
    // return {...oldGameState,
    //   needsToScrollTo: "bottom",};
  }
}

export function gsTypeBackspace(oldGameState) {
  const currentGuess = oldGameState.currentGuess;
  // const pastGuesses = state.pastGuesses;
  // const pastRelativeTrust = state.pastRelativeTrust;
  if (oldGameState.isGivenUp) {
    return {
      ...oldGameState,
      shareCopyStatus: UNSHARED,
      needsToScrollTo: "bottom",
    };
  }
  else if (currentGuess.length > 0) {
    const newToAnimate = taBackspace(oldGameState.toAnimate, currentGuess.length - 1);
    return {
      ...oldGameState,
      currentGuess: currentGuess.slice(0, -1),
      message: '',
      maxLiesAddMessage: '',
      satisfaction: null,
      shareCopyStatus: UNSHARED,
      isGivenUp: false,
      needsToScrollTo: "bottom",
      toAnimate: newToAnimate,
    };
  } else {
    return {
      ...oldGameState,
      message: '',
      maxLiesAddMessage: '',
      satisfaction: null,
      shareCopyStatus: UNSHARED,
      isGivenUp: false,
      needsToScrollTo: "bottom",
    };
  }
}

export function gsToggleBoardEmphasis(oldGameState, boardNumClicked) {
  // should avoid repeating this code to determine isGameOverFinalAfter and emphasizedBoardNumReady
  const isStillAnimatingClue = getIsStillAnimatingClue(oldGameState.toAnimate);
  const isStillAnimatingGivenUp = oldGameState.toAnimate !== null && oldGameState.toAnimate.givenUpRow.isStillAnimatingGivenUp;
  const emphasizedBoardNumReady = ((isStillAnimatingClue || isStillAnimatingGivenUp || oldGameState.nextEmphasizedBoardNum === null) ? oldGameState.emphasizedBoardNum : oldGameState.nextEmphasizedBoardNum);
  const isWinByBoardBA = getIsWinByBoardBA(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall);
  const isWinAfter = isWinByBoardBA.after.every((x) => (x));
  const isGivenUp = oldGameState.isGivenUp;
  const isGameOverFinalAfter = isWinAfter || isGivenUp;

  if (boardNumClicked === emphasizedBoardNumReady) {
    return {
      ...oldGameState,
      emphasizedBoardNum: -1,
      nextEmphasizedBoardNum: (isGameOverFinalAfter ? -1 : null),
    }
  } else {
    return {
      ...oldGameState,
      emphasizedBoardNum: boardNumClicked,
      nextEmphasizedBoardNum: (isGameOverFinalAfter ? -1 : null),
    }
  }
}

export function gsTogglePostgameRows(oldGameState, rowNum) {
  const newPostgameRows = ((oldGameState.postgameRows === null) ? 
    Array(countNumTurnsByGameHistory(oldGameState.gameHistorySmall)).fill(false) :
    [...oldGameState.postgameRows]);
  if (!newPostgameRows[rowNum]) {
    newPostgameRows.fill(false);
  }
  newPostgameRows[rowNum] = !newPostgameRows[rowNum];
  return {...oldGameState,
    postgameRows: newPostgameRows};
}

// game status

function countNumTurns(boardToPastGuesses) {
  let numTurns = 0;
  boardToPastGuesses.forEach((pastGuesses) => {
    if (pastGuesses.length > numTurns) {
      numTurns = pastGuesses.length;
    }
  });
  return numTurns;
}

export function countNumTurnsByGameHistory(gameHistorySmall) {
  return gameHistorySmall.length;
}

function checkBeyondTurnLimitStandalone(numTurns) {
  return (numTurns >= TURN_LIMIT);
}

export function checkGameStatusStandalone(boardToPastGuesses, isOverridingTurnLimit, isGivenUp, isWin) {
  const numTurns = countNumTurns(boardToPastGuesses);
  const isTurnLimitEnding = !isOverridingTurnLimit && checkBeyondTurnLimitStandalone(numTurns);
  const isGameOverFinal = isGivenUp || isWin;
  const isGameOverForNow = isGameOverFinal || isTurnLimitEnding;
  return [numTurns, isTurnLimitEnding, isGameOverFinal, isGameOverForNow];
}

// export function checkGameStatusByGameHistory() {
//   const numTurns = countNumTurnsByGameHistory(gameHistorySmall);
//   const isTurnLimitEnding = !isOverridingTurnLimit && checkBeyondTurnLimitStandalone(numTurns);
// }