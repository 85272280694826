import { useState, useEffect } from 'react';

export function getDefault(obj, key, defaultValue) {
  if (key in obj) {
    return obj[key];
  } else {
    return defaultValue;
  }
}

export function setDefault(obj, key, defaultValue) {
  if (!(key in obj)) {
    obj[key] = defaultValue;
  }
}

export function setDefaultThenAdd(obj, key, defaultValue, summand) {
  setDefault(obj, key, defaultValue);
  obj[key] += summand;
}

export function localStorageGetDefault(key, defaultValue) {
  const stringFromStorage = window.localStorage.getItem(key);
  if (stringFromStorage === null) {
    return defaultValue;
  } else {
    return JSON.parse(stringFromStorage);
  }
}

export function localStorageSet(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function localStorageGetAndSetDefault(key, defaultValue) {
  const stringFromStorage = window.localStorage.getItem(key);
  if (stringFromStorage === null) {
    localStorageSet(key, defaultValue);
    return defaultValue;
  } else {
    return JSON.parse(stringFromStorage);
  }
}

// hook
export function useStateWithLocalStorage(storageKey, defaultValue)
{
  const initialValue = localStorageGetAndSetDefault(storageKey, defaultValue);
  const [varName, setValueNoLS] = useState(initialValue);

  function setValueLS(newValue) {
    setValueNoLS(newValue);
  }

  useEffect(() => {
    localStorageSet(storageKey, varName);
  }, [storageKey, varName]);

  return [varName, setValueLS];
}