
// initializing

function getFreshToAnimatePastRow(wordLength) {
  return {
    isAnimateClue: false,
    isStillAnimatingClue: true,
    // backsideInfo: null,
  }
}

function getFreshToAnimateTypingRow(wordLength) {
  return {
    isAnimateTypingRowAppearance: false,
    typedLettersToAnimate: Array(wordLength).fill(false),
  };
}

function getFreshToAnimateGivenUpRow() {
  return {
    isAnimateGivenUp: false,
    isStillAnimatingGivenUp: false,
  };
}

// export function getNumGuesses(gameState) {
//   return gameState.gameHistorySmall.length;
// }

export function getFreshToAnimateIfNull(wordLength, numGuesses, oldToAnimate) {
  if (oldToAnimate !== null) {
    return oldToAnimate;
  }

  const toAnimate = {
    pastRows: Array(numGuesses),
    activeRow: getFreshToAnimateTypingRow(wordLength),
    // isStillAnimatingClue: false,
    givenUpRow: getFreshToAnimateGivenUpRow(),
    // outgoingGameState: null,
  }
  for (let rowNum = 0; rowNum < numGuesses; rowNum++) {
    toAnimate.pastRows[rowNum] = getFreshToAnimatePastRow(wordLength);
  }
  // toAnimate.pastRows.forEach( (discard, rowNum) => {
  //   toAnimate.pastRows[rowNum] = getFreshToAnimateOneRow(wordLength);
  // });
  // toAnimate[numGuesses].isAnimateClue = true;

  return toAnimate;
}


// responding to actions

export function taTypeLetter(oldToAnimate, wordLength, numGuesses, position) {
  const oldToAnimateNotNull = getFreshToAnimateIfNull(wordLength, numGuesses, oldToAnimate);
  // console.log("oldToAnimateNotNull", oldToAnimateNotNull);
  // console.log("taTypeLetter oldToAnimateNotNull", oldToAnimateNotNull);
  const oldTypedLettersToAnimate = oldToAnimateNotNull.activeRow.typedLettersToAnimate;
  const newTypedLettersToAnimate = [...oldTypedLettersToAnimate];
  newTypedLettersToAnimate[position] = true;
  const newActiveRow = {...oldToAnimateNotNull.activeRow, typedLettersToAnimate: newTypedLettersToAnimate};
  const newToAnimate = {...oldToAnimateNotNull, activeRow: newActiveRow};
  // console.log("taTypeLetter toAnimate", oldToAnimateNotNull, newToAnimate);
  return newToAnimate;
}

export function taBackspace(oldToAnimateNotNull, position) {
  // console.log("oldToAnimateNotNull", oldToAnimateNotNull);
  // console.log("taTypeLetter oldToAnimateNotNull", oldToAnimateNotNull);
  const oldTypedLettersToAnimate = oldToAnimateNotNull.activeRow.typedLettersToAnimate;
  const newTypedLettersToAnimate = [...oldTypedLettersToAnimate];
  newTypedLettersToAnimate[position] = false;
  const newActiveRow = {...oldToAnimateNotNull.activeRow, typedLettersToAnimate: newTypedLettersToAnimate};
  const newToAnimate = {...oldToAnimateNotNull, activeRow: newActiveRow};
  // console.log("taTypeLetter toAnimate", oldToAnimateNotNull, newToAnimate);
  return newToAnimate;
}


// export function taSubmitGuess(oldToAnimateNotNull, oldGameState, wordInfo) { //, wordLength, wordInfo, keyboardAims) {
export function taSubmitGuess(oldToAnimate, wordLength, numGuesses) { //, wordLength, wordInfo, keyboardAims) {
  const oldToAnimateNotNull = getFreshToAnimateIfNull(wordLength, numGuesses, oldToAnimate);
  // const wordLength = oldGameState.wordLength;
  // const oldBoardToPastGuesses = getBoardToPastGuesses(oldGameState.gameParams.wordLength, oldGameState.gameParams.numBoards, oldGameState.actualWords, oldGameState.gameHistorySmall).after;
  // const keyboardAims = computeAllKeyboardAims(oldGameState.gameParams.wordLength, oldBoardToPastGuesses, MAX_LIES);
  const appliedRow = getFreshToAnimatePastRow(wordLength);
  // appliedRow.backsideInfo = {
  //   wordInfo: wordInfo,
  //   keyboardAims: keyboardAims,
  // };
  appliedRow.isAnimateClue = true;

  const newToAnimate = {
    pastRows: [...oldToAnimateNotNull.pastRows, appliedRow],
    activeRow: getFreshToAnimateTypingRow(wordLength),
    givenUpRow: getFreshToAnimateGivenUpRow(),
    // isStillAnimatingClue: true,
    // outgoingGameState: oldGameState,
  }
  newToAnimate.activeRow.isAnimateTypingRowAppearance = true;

  return newToAnimate;
}

export function taGiveUp(oldToAnimate, wordLength, numGuesses) {
  const oldToAnimateNotNull = getFreshToAnimateIfNull(wordLength, numGuesses, oldToAnimate);

  const newToAnimate = {
    ...oldToAnimateNotNull,
    givenUpRow: {
      isAnimateGivenUp: true,
      isStillAnimatingGivenUp: true,
    },
  }

  return newToAnimate;
}

// export function taNoLongerAnimatingClue(oldToAnimate) {
//   return {
//     ...oldToAnimate,
//     isStillAnimatingClue: false,
//     // outgoingGameState: null,
//   }
// }

export function gsHandleClueAnimationEnd(oldGameState, eventViewingId, eventRowNum) {
  // console.log("gsHandleClueAnimationEnd", eventViewingId, eventRowNum);
  if (eventViewingId === oldGameState.viewingId) { // should automatically mean eventRowNum is a valid index into pastRows
    const newOneRow = {
      ...oldGameState.toAnimate.pastRows[eventRowNum],
      isStillAnimatingClue: false,
    };
    const newPastRows = [...oldGameState.toAnimate.pastRows];
    newPastRows[eventRowNum] = newOneRow;
    const newToAnimate = {
      ...oldGameState.toAnimate,
      pastRows: newPastRows,
    };
    const newGameState = {
      ...oldGameState,
      toAnimate: newToAnimate,
    }
    return newGameState;
  } else {
    return oldGameState;
  }
}

export function gsHandleGivenUpAnimationEnd(oldGameState, eventViewingId) {
  console.log("gsHandleGivenUpAnimationEnd", eventViewingId);
  if (eventViewingId === oldGameState.viewingId) {
    const newToAnimate = {
      ...oldGameState.toAnimate,
      givenUpRow: {
        ...oldGameState.toAnimate.givenUpRow,
        isStillAnimatingGivenUp: false,
      },
    };
    const newGameState = {
      ...oldGameState,
      toAnimate: newToAnimate,
    }
    return newGameState;
  } else {
    return oldGameState;
  }
}

export function getIsStillAnimatingClue(toAnimate) {
  return toAnimate !== null && toAnimate.pastRows.length > 0 && toAnimate.pastRows[toAnimate.pastRows.length - 1].isAnimateClue && toAnimate.pastRows[toAnimate.pastRows.length - 1].isStillAnimatingClue;
}