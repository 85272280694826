import { getDefault, setDefault, setDefaultThenAdd } from "./Storage.js";

// Tarjan's algorithm for finding strongly connected components in a digraph.
// Should take in an object mapping a (string) key to an object whose keys are the successors.
// Reference: https://en.wikipedia.org/wiki/Tarjan%27s_strongly_connected_components_algorithm
function tarjan(digraph) {
    let nextIndex = 0;
    const stack = [];
    const vtxInfo = {};
    const vtxToComponent = {};
    // const componentToVtxs = {};
    for (const v in digraph) {
        if (!(v in vtxInfo)) {
            strongConnect(v);
        }
    }
    
    return vtxToComponent;

    function strongConnect(v) {
        if (v in vtxInfo) {
            console.error("tarjan strongConnect: vertex already visited.")
        } else {
            vtxInfo[v] = {
                index: nextIndex,
                lowLink: nextIndex,
                onStack: true,
            }
            nextIndex++;
            stack.push(v);

            // for (const wIndex in digraph[v]) {
            //     const w = digraph[v][wIndex];
            for (const w in digraph[v]) {
                if (!(w in vtxInfo)) {
                    strongConnect(w);
                    vtxInfo[v].lowLink = Math.min(vtxInfo[v].lowLink, vtxInfo[w].lowLink);
                } else if (vtxInfo[w].onStack) {
                    vtxInfo[v].lowLink = Math.min(vtxInfo[v].lowLink, vtxInfo[w].index); // reference is clear that it really is .index in that second parameter not .lowLink
                }
            }

            if (vtxInfo[v].lowLink === vtxInfo[v].index) {
                // start a new strongly connected component
                let vFound = false;
                const componentIndex = vtxInfo[v].index
                // componentToVtxs[componentIndex] = [];
                while (!vFound) {
                    const w = stack.pop();
                    vtxInfo[w].onStack = false;
                    vtxToComponent[w] = componentIndex;
                    // componentToVtxs[componentIndex].push(w);
                    vFound = (w === v);
                }
            }
        }
    }
}

// Should take in an object mapping a (string) key to an object whose keys are the successors.
function findCycleWithEdge(digraph, vtxToComponent, head, tail) {
    if (vtxToComponent[head] !== vtxToComponent[tail]) {
        console.error("findCycleWithEdge: head and tail vertices are not in the same strongly connected component, so no such cycle exists.");
        return;
    }

    const path = [head];
    const visited = new Set();
    const success = dfs(tail);
    if (!success) {
        console.error("findCycleWithEdge: failed to find cycle even though one should exist");
    }

    return path;

    function dfs(v) {
        if (visited.has(v)) {
            return false;
        }

        visited.add(v);
        path.push(v);
        if (v === head) {
            return true;
        }
        // for (const wIndex in digraph[v]) {
        //     const w = digraph[v][wIndex];
        for (const w in digraph[v]) {
            if (vtxToComponent[v] === vtxToComponent[w]) {
                const success = dfs(w);
                if (success) {
                    return true;
                }
            }
        }
        const vPopped = path.pop();
        if (vPopped !== v) {
            console.error("findCycleWithEdge dfs: vPopped should equal v");
        }
        return false;
    }
}

// checks whether minCapacity is always less than or equal to maxCapacity
function validNetwork(network) {
    for (const v in network) {
        for (const w in network[v]) {
            if (network[v][w].minCapacity > network[v][w].maxCapacity) {
                return false;
            }
        }
    }
    
    return true;
}

// network represented as an object that maps vertex names (heads) to an object; that object maps vertex names (tails) to objects; those object have keys minCapacity and maxCapacity.
// flow represented as an object that maps vertex names (heads) to an object; that object maps vertex names (tails) to flow value.
// residual represented as an object that maps vertex names (heads) to an object; that object maps vertex names (tails) to GREATER THAN ZERO weight (residual capacity).
function getResidual(network, flow) {
    const residual = {};

    for (const v in flow) {
        for (const w in flow[v]) {
            if (!(v in network) || !(w in network[v])) {
                console.error("getResidual: flow contains edge not in network")
                return;
            }
        }
    }

    const needsMoreFlow = [];
    for (const v in network) {
        setDefault(residual, v, {})
        for (const w in network[v]) {
            const minCapacityValue = network[v][w].minCapacity;
            const maxCapacityValue = network[v][w].maxCapacity;
            if (minCapacityValue > maxCapacityValue) {
                // console.log("error", v, w, minCapacityValue, maxCapacityValue)
                console.error("getResidual: in network, minCapacity must be less than or equal to maxCapacity");
                return;
            }
            const vFlow = getDefault(flow, v, {});
            let flowValue = getDefault(vFlow, w, 0);
            if (flowValue < minCapacityValue) {
                // flowValue = minCapacityValue;
                // validFlow = false;
                needsMoreFlow.push([v, w]);
            } else if (flowValue > maxCapacityValue) {
                // flowValue = maxCapacityValue;
                // validFlow = false;
                needsMoreFlow.push([w, v]);
            }
            if (flowValue < maxCapacityValue) { // only add edge in residual if it will have nonzero weight
                setDefaultThenAdd(residual[v], w, 0, maxCapacityValue - flowValue);
            }
            if (minCapacityValue < flowValue) { // only add edge in residual if it will have nonzero weight
                setDefault(residual, w, {});
                setDefaultThenAdd(residual[w], v, 0, flowValue - minCapacityValue);
            }
        }
    }

    return [residual, needsMoreFlow];
}

// residual should have all GREATER THAN ZERO values
function addFlowToEdge(network, flow, residual, residualVtxToComponent, head, tail) {
    if (!(head in residual) || !(tail in residual[head])) {
        // console.error("addFlowToEdge: trying to add flow where there is no capacity");
        return [false, null]; // [success, newFlow]
    }

    if (residualVtxToComponent[head] !== residualVtxToComponent[tail]) {
        return [false, null]; // [success, newFlow]
    }

    const path = findCycleWithEdge(residual, residualVtxToComponent, head, tail);
    let maxFlowChange = getMinWeightAlongPath(residual, path); // maxFlowChange should be greater than zero because all residual values should be.

    return [true, addFlowToCycle(network, flow, path, maxFlowChange)];
}

function getMinWeightAlongPath(weightedDigraph, path) {
    let minWeight = null;
    for (let i = 0; i < path.length - 1; i++) {
        const v = path[i];
        const w = path[i + 1];
        const weightValue = weightedDigraph[v][w]; // these keys should exist
        if (minWeight === null || weightValue < minWeight) {
            minWeight = weightValue;
        }
    }

    return minWeight;
}

function addFlowToCycle(network, flow, path, flowChange) {
    if (path[0] !== path[path.length - 1]) {
        console.error("addFlowToCycle: first vertex of path must equal last");
        return;
    }
    // newNetwork starts as a deep copy of network
    const newFlow = {};
    for (const v in flow) {
        newFlow[v] = {...flow[v]};
    }

    for (let i = 0; i < path.length - 1; i++) {
        const v = path[i];
        const w = path[i + 1];
        // try to back off existing flow first THIS PROBABLY DOESN'T WORK WITH SELF-LOOPS
        let remainingFlowChange = flowChange;
        if (w in newFlow && v in newFlow[w]) {
            const remainingBackCapacity = newFlow[w][v] - network[w][v].minCapacity;
            if (flowChange <= remainingBackCapacity) {
                newFlow[w][v] -= flowChange;
                remainingFlowChange = 0;
            } else {
                newFlow[w][v] = network[w][v].minCapacity; // equivalent to subtracting off remainingBackCapacity
                remainingFlowChange = flowChange - remainingBackCapacity;
                // newFlow[v][w] += flowChange - remainingBackCapacity;
                // if (newFlow[v][w] > network[v][w].maxCapacity) {
                //     console.error("addFlowToCycle: flowChange inconsistent with capacities; trying to add too much flow to edge");
                //     return;
                // }
            }
        }

        if (remainingFlowChange > 0) {
            setDefault(newFlow, v, {});
            setDefaultThenAdd(newFlow[v], w, 0, remainingFlowChange);
            if (!(v in network) || !(w in network[v]) || newFlow[v][w] > network[v][w].maxCapacity) {
                console.error("addFlowToCycle: flowChange inconsistent with network or capacities; trying to add too much flow to edge or add flow to an edge that doesn't exist");
                return;
            }
        }
    }

    return newFlow;
}

export function fixFlow(network, flow) {
    // let tries = 10;
    // while (tries > 0) {
    while (true) {
        if (!validNetwork(network)) {
            return [false, null];
        }
        const [residual, needsMoreFlow] = getResidual(network, flow);
        // console.log("needsMoreFlow", needsMoreFlow);
        if (needsMoreFlow.length === 0) {
            return [true, flow];
        }
        const [head, tail] = needsMoreFlow[0];
        const residualVtxToComponent = tarjan(residual);
        const [success, newFlow] = addFlowToEdge(network, flow, residual, residualVtxToComponent, head, tail);
        if (!success) {
            return [false, null]
        }
        flow = newFlow;
        // tries--;
    }
}

function maximizeFlowOnEdge(network, flowStart, head, tail) {
    let [success, newFlow] = fixFlow(network, flowStart);
    if (!success) {
        return [false, null];
    }
    let flow;
    while (success) {
        flow = newFlow;
        const [residual, needsMoreFlow] = getResidual(network, flow);
        if (needsMoreFlow !== null) {
            console.error("maximizeFlowOnEdge: needsMoreFlow should be null because already ran fixFlow")
        }
        const residualVtxToComponent = tarjan(residual);
        [success, newFlow] = addFlowToEdge(network, flow, residual, residualVtxToComponent, head, tail);
    }
    return [true, flow];
}

function minimizeFlowOnEdge(network, flowStart, head, tail) {
    return maximizeFlowOnEdge(network, flowStart, tail, head);
}

function makeDigraph(objWithLists) {
    const digraph = {}
    for (const head in objWithLists) {
        digraph[head] = {}
        for (const i in objWithLists[head]) {
            const tail = objWithLists[head][i];
            digraph[head][tail] = null;
        }
    }

    return digraph;
}

const exampleDigraph = makeDigraph({
    a: ['b'],
    b: ['c'],
    c: ['a'],
    d: ['b', 'c', 'e'],
    e: ['d', 'f'],
    f: ['c', 'g'],
    g: ['f'],
    h: ['e', 'g', 'h'],
});

const exampleNetwork = {};
const exampleFlow = {};
for (const v in exampleDigraph) {
    exampleNetwork[v] = {}
    exampleFlow[v] = {}
    for (const w in exampleDigraph[v]) {
        exampleNetwork[v][w] = {minCapacity: 0, maxCapacity: 3}
        exampleFlow[v][w] = 0;
    }
}
// exampleNetwork['a']['b'].minCapacity = 1;
exampleFlow['a']['b'] = 4;
exampleFlow['b']['c'] = 3;
exampleFlow['c']['a'] = 3;

// const exampleVtxToComponent = tarjan(exampleDigraph);
const [exampleResidual, ] = getResidual(exampleNetwork, exampleFlow);
const exampleResidualVtxToComponent = tarjan(exampleResidual);
const examplePath = findCycleWithEdge(exampleResidual, exampleResidualVtxToComponent, "a", "b");
const exampleFlowChange = getMinWeightAlongPath(exampleResidual, examplePath);
const exampleNewFlow = addFlowToCycle(exampleNetwork, exampleFlow, examplePath, exampleFlowChange);