import { MAX_LIES, ALL_LETTERS, ALL_ACTUAL, HIT, CLOSE, MISS, UNEXPLORED, CSS_CLASS } from "./Constants";
import { computePattern, getLiesLeftToCountAndCumulativeProgress, getFreshNetwork, getFreshFlow, memoizeByGameHistory } from "./Mechanics";
import { setDefault, setDefaultThenAdd } from "./Storage";
import { fixFlow } from "./Graphs";

const IN_POSITION = "in position";
const WITH_MULT = "with multiplicity";

// basics of working with conditions

function checkConditionOne(word, condition) {
  // console.log("condition", condition);
  const [letter, condType, number] = condition;
  // console.log("condition", condition);
  // console.log("[letter, condType, number]", [letter, condType, number]);
  if (condType === IN_POSITION) {
    return word[number] === letter;
  } else if (condType === WITH_MULT) {
    let count = 0;
    for (let i = 0; i < word.length; i++) {
      if (letter === word[i]) {
        count++;
      }
    }
    return (count >= number);
  } else {
    console.log("condition", condition);
    console.log("[letter, condType, number]", [letter, condType, number]);
    console.error("checkConditionOne: condition type condType should be either IN_POSITION or WITH_MULT");
  }
}

function checkConditionList(word, conditionList) {
  const valuedConditions = Array(conditionList.length);
  for (let i = 0; i < conditionList.length; i++) {
    const condition = conditionList[i];
    valuedConditions[i] = {
      condition: condition,
      value: checkConditionOne(word, condition),
    }
  }
  return valuedConditions;
}

function checkConditionsMatch(word, valuedConditions) {
  const outcomes = {};
  let outcomeCount = 0;
  for (let i = 0; i < valuedConditions.length; i++) {
    const condition = valuedConditions[i].condition;
    outcomes[i] = checkConditionOne(word, condition) === valuedConditions[i].value;
    if (!outcomes[i]) {
      outcomeCount++;
    }
  }
  return [outcomes, outcomeCount];
}

function filterActualToLiesLeftByConditionList(actualToLiesLeft, valuedConditions) {
  const newActualToLiesLeft = {};
  for (const actualOption in actualToLiesLeft) {
    const numLeft = actualToLiesLeft[actualOption];
    const [ , numNotMatch] = checkConditionsMatch(actualOption, valuedConditions);
    if (numNotMatch === 0) {
      newActualToLiesLeft[actualOption] = numLeft;
    } else if (numLeft >= 1) {
      newActualToLiesLeft[actualOption] = numLeft - 1;
    }
  }
  return newActualToLiesLeft;
}

function guessToConditionList(guessedWord) {
  // console.log("guessedWord", guessedWord);
  const conditionList = Array(guessedWord.length * 2);
  const guessLetterCount = {};
  for (let i = 0; i < guessedWord.length; i++) {
    const g = guessedWord[i]
    conditionList[i] = [g, IN_POSITION, i];
    setDefaultThenAdd(guessLetterCount, g, 0, 1);
    conditionList[i + guessedWord.length] = [g, WITH_MULT, guessLetterCount[g]];
  }
  return conditionList;
}

export function computeConditionValues(actualWord, guessedWord) {
  const conditionList = guessToConditionList(guessedWord);
  const valuedConditions = checkConditionList(actualWord, conditionList);
  return valuedConditions;
}

// analysis

// helper for analyzeGuessMoral
function categorizeValuedConditionsByLetter(valuedConditions) {
  const valuedConditionsByLetter = {}
  valuedConditions.forEach((valCond, i) => {
    const [letter, , ] = valCond.condition;
    setDefault(valuedConditionsByLetter, letter, []);
    valuedConditionsByLetter[letter].push(valCond);
  });
  return valuedConditionsByLetter
}

// helper for analyzeGuessMoral
function getSortedLettersAndProgress(visBefore, visAfterLetter, valuedConditionsByLetter) {
  const sortedLettersAndProgress =  Object.keys(visAfterLetter).map(letter => {
    return {
      letter: letter,
      progressChange: visAfterLetter[letter].progress - visBefore.progress,
      previousLetters: null,
      progressAdjustment: null,
    };
  });
  sortedLettersAndProgress.sort((a, b) => b.progressChange - a.progressChange);
  if (sortedLettersAndProgress.length === 0) {
    console.error("getSortedLettersAndProgressAdjustment: expected at least one letter");
  }
  const top = sortedLettersAndProgress[0];
  top.progressAdjustment = 0;
  let accumulatedVis = visAfterLetter[top.letter];
  for (let i = 1; i < sortedLettersAndProgress.length; i++) {
    const letterAndProgress = sortedLettersAndProgress[i];
    // const letterVis = visAfterLetter[letterAndProgress.letter];
    const letterValuedConditions = valuedConditionsByLetter[letterAndProgress.letter];
    // console.log("accumulatedVis", accumulatedVis);
    const newAccumulatedVis = applyConditionListToVis(accumulatedVis, letterValuedConditions);
    letterAndProgress.previousLetters = sortedLettersAndProgress.slice(0, i).map(lap => lap.letter).join(", ").toUpperCase();
    letterAndProgress.progressAdjustment = newAccumulatedVis.progress - accumulatedVis.progress - letterAndProgress.progressChange;
    accumulatedVis = newAccumulatedVis;
  }
  return [sortedLettersAndProgress, accumulatedVis]; // accumulatedVis has not set after.isInProgress
}

const KNOWN_FROM_ASKING = "asking";
const KNOWN_FROM_DEDUCTION = "implies";
const KNOWN_FROM_WORDLIST = "wordlist";
const NOT_KNOWN = "not";
const WZ_TEXT = {
  [KNOWN_FROM_ASKING]: "", //"(A)", 
  [KNOWN_FROM_DEDUCTION]: "*", //"(D)",
  [KNOWN_FROM_WORDLIST]: "**", //"(WL)",
  [NOT_KNOWN]: "",
}

// helper for analyzeOneLetterMoral, which is itself helper for analyzGuessMoral
function checkIfConditionKnown(vis, condition, conditionValue) {
  if (!checkIfWordlistImpliesCondition(vis.actualToLiesLeft, condition, conditionValue)) {
    return NOT_KNOWN;
  } else if (checkIfNetworkStatesCondition(vis.network, condition, conditionValue)) {
    return KNOWN_FROM_ASKING;
  } else if (checkIfNetworkImpliesCondition(vis.network, vis.candidateFlow, condition, conditionValue)) {
    return KNOWN_FROM_DEDUCTION;
  } else {
    return KNOWN_FROM_WORDLIST;
  }
}

// helper for analyzeOneLetterMoral, which is itself helper for analyzGuessMoral
function numberSortingFunction(valCondWzA, valCondWzB) {
  const [valCondA, ] = valCondWzA;
  const [valCondB, ] = valCondWzB;
  const [ , , numberA] = valCondA.condition;
  const [ , , numberB] = valCondB.condition;
  return numberA - numberB;
}

// helper for analyzeOneLetterMoral, which is itself helper for analyzGuessMoral
function getConditionText(condition, value) {
  const [letter, condType, number] = condition;
  if (condType === IN_POSITION) {
    return (value ? "" : "Not ") + ORDINALS[number] + " letter";
    // return <>{(value ? "" : "Not ")} {ORDINALS[number]} letter"</>;
  } else if (condType === WITH_MULT) {
    const [beforeWords, afterWords] = MULTIPLICITY_WORDS[value][number];
    return beforeWords;
  } else {
    console.error("getConditionText: condition type condType should be either IN_POSITION or WITH_MULT");
  }
}

export function getAimFromNetwork(network, letter) {
  if (network === null) {
    return UNEXPLORED;
  }
  // console.log(letter, network[letter]);
  // for (let i = 0; i < network[letter].length; i++) {
  for (const i in network[letter]) {
    if (network[letter][i].minCapacity > 0) {
      return HIT;
    }
  }
  if (network['*'][letter].minCapacity > 0) {
    return CLOSE;
  }
  if (network['*'][letter].maxCapacity === 0) {
    return MISS;
  }
  return UNEXPLORED;
}

function LetterProgressTile({letter, aimBefore, aimAfter}) {
  return <div className={"letter-progress-tile " + CSS_CLASS[aimBefore] + "-" + CSS_CLASS[aimAfter]}>{letter.toUpperCase()}</div>;
}

// helper for analyzeGuessMoral
function analyzeOneLetterMoral(visBefore, letterAndProgress, aimBefore, aimAfter, letterValuedConditions) {
  const categorized = {
    new: {
      positiveInPosition: [],
      multiplicity: [],
      negativeInPosition: [],
    },
    old: {
      positiveInPosition: [],
      multiplicity: [],
      negativeInPosition: [],
    },
  }
  letterValuedConditions.forEach(valCond => {
    const wz = checkIfConditionKnown(visBefore, valCond.condition, valCond.value);
    const newness = (wz === NOT_KNOWN ? "new" : "old");
    const [letter, condType, number] = valCond.condition;
    if (condType === WITH_MULT) {
      categorized[newness].multiplicity.push([valCond, wz]);
    } else if (condType === IN_POSITION) {
      if (valCond.value) {
        categorized[newness].positiveInPosition.push([valCond, wz]);
      } else {
        categorized[newness].negativeInPosition.push([valCond, wz]);
      }
    } else {
      console.error("analyzeOneLetterMoral: condition type condType should be either IN_POSITION or WITH_MULT");
    }
  });
  let accumulatedVis = visBefore;
  let progressItems = [];
  let numHitNow = categorized.new.positiveInPosition.length + categorized.old.positiveInPosition.length;
  let firstMultiplicityFalse = null;
  let firstMultiplicityOldFalse = null;
  letterValuedConditions.forEach(valCond => {
    const [letter, condType, number] = valCond.condition;
    if (condType === WITH_MULT && valCond.value === false && (firstMultiplicityFalse === null || number < firstMultiplicityFalse)) {
      firstMultiplicityFalse = number;
    }
  })
  let shouldShowNewNegatives = (firstMultiplicityFalse !== numHitNow + 1);
  // let isContinueListing = true;
  ["new", "old"].forEach(newness => {
    ["positiveInPosition", "multiplicity", "negativeInPosition"].forEach(signedType => {
      // console.log("categorized[newness][k]", categorized[newness][k]);
      const category = categorized[newness][signedType]
      category.sort(numberSortingFunction);
      if (newness === "new" && signedType !== "multiplicity") {
        if (category.length > 0 && (signedType === "positiveInPosition" || shouldShowNewNegatives)) {
          const valuedConditions = category.map(([valCond, wz]) => valCond);
          const newAccumulatedVis = applyConditionListToVis(accumulatedVis, valuedConditions);
          const progressChange = newAccumulatedVis.progress - accumulatedVis.progress;
          progressItems.push(<div key={newness + "-" + signedType}>
            <strong>+{(100 * progressChange).toFixed(0)}%</strong> {valuedConditions.map(valCond => getConditionText(valCond.condition, valCond.value)).join(", ")}
          </div>)
          accumulatedVis = newAccumulatedVis;
        }
      } else if (newness === "new") { // here signedType === "multiplicity"
        let isContinueListingThisCategory = true;
        category.forEach(([valCond, wz]) => {
          if (isContinueListingThisCategory) {
            const [ , , number] = valCond.condition;
            if (number > numHitNow) {
              const newAccumulatedVis = applyConditionListToVis(accumulatedVis, [valCond]);
              const progressChange = newAccumulatedVis.progress - accumulatedVis.progress;
              progressItems.push(<div key={valCond.condition}>
                <strong>+{(100 * progressChange).toFixed(0)}%</strong> {getConditionText(valCond.condition, valCond.value)}
              </div>)
              accumulatedVis = newAccumulatedVis;
            } else {
              // progressItems.push(<div key={valCond.condition}>
              //   Skipping {getConditionText(valCond.condition, valCond.value)}
              // </div>)
            }
            if (!valCond.value) {
              isContinueListingThisCategory = false;
            }
          }
        })
      } else if (signedType !== "multiplicity") { // "old"
        if (signedType === "positiveInPosition" || firstMultiplicityOldFalse === null || firstMultiplicityOldFalse !== categorized.old.positiveInPosition.length + 1) {
          category.forEach(([valCond, wz]) => {
            progressItems.push(<div key={valCond.condition}>
              <em>Had: {getConditionText(valCond.condition, valCond.value)} {WZ_TEXT[wz]}</em>
            </div>)
          });
        }
      } else { // "old" "multiplicity"
        let atLeastItem = null;
        let atMostItem = null;
        category.forEach(([valCond, wz]) => {
          const item = (<div key={valCond.condition}>
              <em>Had: {getConditionText(valCond.condition, valCond.value)} {WZ_TEXT[wz]}</em>
            </div>);
          if (valCond.value) {
            atLeastItem = item;
          } else if (atMostItem === null) {
            atMostItem = item;
            const [ , , number] = valCond.condition;
            firstMultiplicityOldFalse = number;
          }
        });
        if (atLeastItem !== null) {
          progressItems.push(atLeastItem);
        }
        if (atMostItem !== null) {
          progressItems.push(atMostItem);
        }
      }
    });
  });
  // console.log(categorized);
  // return progressItems;

  
  let progressAdjustmentElement = null;
  if (letterAndProgress.progressAdjustment >= 0.01) {
    progressAdjustmentElement = <div><strong>+{(100 * letterAndProgress.progressAdjustment).toFixed(0)}%</strong> combo with {letterAndProgress.previousLetters}</div>
  } else if (letterAndProgress.progressAdjustment <= -0.01) {
    progressAdjustmentElement = <div><strong>{(100 * letterAndProgress.progressAdjustment).toFixed(0)}%</strong> redundant with {letterAndProgress.previousLetters}</div>
  }
  return (<div key={letterAndProgress.letter} className="letter-progress-card">
    <LetterProgressTile letter={letterAndProgress.letter} aimBefore={aimBefore} aimAfter={aimAfter} />
    <div className="letter-progress-text">
      {progressItems}
      {progressAdjustmentElement}
    </div>
  </div>)
}

function analyzeGuessMoral(visBefore, actualWord, guessedWord) {
  const valuedConditions = computeConditionValues(actualWord, guessedWord); // REDUNDANT with applyGuessToVis :(
  const valuedConditionsByLetter = categorizeValuedConditionsByLetter(valuedConditions);
  const visAfterLetter = {};
  for (const letter in valuedConditionsByLetter) {
    const letterValuedConditions = valuedConditionsByLetter[letter];
    visAfterLetter[letter] = applyConditionListToVis(visBefore, letterValuedConditions);
  }
  const [sortedLettersAndProgress, visAfter] = getSortedLettersAndProgress(visBefore, visAfterLetter, valuedConditionsByLetter); // afterVis does not have updated isInProgress from this call; need to set it in next line
  visAfter.isInProgress = (guessedWord !== actualWord);
  const cards = sortedLettersAndProgress.map(letterAndProgress => {
    const aimBefore = getAimFromNetwork(visBefore.network, letterAndProgress.letter);
    const aimAfter = getAimFromNetwork(visAfter.network, letterAndProgress.letter);
    // console.log("aimAfter", visAfter.network, letterAndProgress, aimAfter);
    return analyzeOneLetterMoral(visBefore, letterAndProgress, aimBefore, aimAfter, valuedConditionsByLetter[letterAndProgress.letter])
  });
  const responsibilitiesText = <div className="letter-progress-wrapper">
    <div className="letter-progress-card">Progress by letter:</div>
    {cards}
  </div>
  return [visAfter, responsibilitiesText];
}

// const ORDINALS = [<>1<sup>st</sup></>, <>2<sup>nd</sup></>, <>3<sup>rd</sup></>, <>4<sup>th</sup></>, <>5<sup>th</sup></>];
const ORDINALS = ["1st", "2nd", "3rd", "4th", "5th"];
const MULTIPLICITY_WORDS = {
  true: [null, ["At least one", ""], ["At least two", "'s"], ["At least three", "'s"]],
  false: [null, ["Not in word", "'s"], ["At most one", ""], ["At most two", "'s"]],
  both: [null, "Exactly one", "Exactly two", "Exactly three"],
};

// const ORDINALS = ["First", "Second", "Third", "Fourth", "Fifth"];
// const MULTIPLICITY_WORDS = {
//   true: [null, ["Contains a", ""], ["Contains two", "'s"], ["Contains three", "'s"]],
//   false: [null, ["No", "'s"], ["At most one", ""], ["At most two", "'s"]],
// };

// doesn't set after.isInProgress
function applyConditionListToVis(before, valuedConditions) {
  const after = {};
  // console.log("applyConditionListToVis valuedConditions", valuedConditions);
  after.network = applyConditionListToNetworkAsCopy(before.network, valuedConditions);
  const [flowSuccessAfter, candidateFlowAfter] = fixFlow(after.network, before.candidateFlow);
  if (!flowSuccessAfter) {
    console.error("applyConditionListToVis (helper of getMoralDataNoMemo): expected to be able to find candidateFlow to satisfy actual network");
  }
  after.candidateFlow = candidateFlowAfter;
  // [after.actualToLiesLeft, after.responsibilitiesText] = analyzeGuessByCondition(before.actualToLiesLeft, before.progress, before.network, before.candidateFlow, conditionList, conditionValues);
  after.actualToLiesLeft = filterActualToLiesLeftByConditionList(before.actualToLiesLeft, valuedConditions);
  // after.actualToLiesLeft = actualToLiesLeftAfter;
  const [liesLeftToCountAfter, progressAfter] = getLiesLeftToCountAndCumulativeProgress(after.actualToLiesLeft);
  after.numOptions = liesLeftToCountAfter["0"];
  after.progress = progressAfter;
  after.isInProgress = null; // update this elsewhere if you need it

  return after;
}

function getOptionsToDisplay(visBefore, visAfter) {
  var optionsToDisplay = null;
  if (visAfter.numOptions <= 6) {
    const remainingOptionsList = Object.keys(visAfter.actualToLiesLeft);
    optionsToDisplay = remainingOptionsList.reduce((accum, value) => ((accum === null) ? [value] : [...accum, ", ", value]), null);
    if (visBefore.numOptions <= 9) {
      const eliminatedOptionsList = [];
      for (const option in visBefore.actualToLiesLeft) {
        if (!remainingOptionsList.includes(option)) {
          eliminatedOptionsList.push(option);
        }
      }
      optionsToDisplay = eliminatedOptionsList.reduce((accum, value) => [...accum, ", ", <span key={value} className="eliminated-option">{value}</span>], optionsToDisplay);
    }
  }

  return optionsToDisplay;
}

function getFreshVisAndResponsibilityTextNEW(wordLength) {
  const newActualToLiesLeft = {};
  ALL_ACTUAL[wordLength].forEach(actualOption => {newActualToLiesLeft[actualOption] = MAX_LIES;});
  const [newLiesLeftToCount, newProgress] = getLiesLeftToCountAndCumulativeProgress(newActualToLiesLeft);
  const newNumOptions = newLiesLeftToCount["0"];
  const newNetwork = getFreshNetwork(wordLength);
  const newCandidateFlow = getFreshFlow(wordLength);
  // const [newNetwork, newCandidateFlow] = makeNewPuzzleNetworkAndFlow(wordLength);
  const vis = {
    actualToLiesLeft: newActualToLiesLeft,
    numOptions: newNumOptions,
    progress: newProgress,
    network: newNetwork,
    candidateFlow: newCandidateFlow,
    isInProgress: true,
  };
  return [vis, null];
}

function applyGuessFalseVisAndResponsibilityText(wordLength, actualWord, currentGuess, visBeforeAndResponsibilityText) {
  const [visBefore, ] = visBeforeAndResponsibilityText;
  return [visBefore, null];
}

function applyGuessTrueVisAndResponsibilityText(wordLength, actualWord, currentGuess, visBeforeAndResponsibilityText) {
  const [visBefore, ] = visBeforeAndResponsibilityText;
  if (visBefore.isInProgress) {
    const [visAfter, responsibilitiesText] = analyzeGuessMoral(visBefore, actualWord, currentGuess);
    return [visAfter, responsibilitiesText];
  } else {
    return [visBefore, null];
  }
}

const getBoardToVisAndResponsibilityTextNEW = memoizeByGameHistory("visAndResponsibilityText", getFreshVisAndResponsibilityTextNEW, applyGuessFalseVisAndResponsibilityText, applyGuessTrueVisAndResponsibilityText);

export function getMoralDataOneRowNEW(wordLength, numBoards, actualWords, gameHistorySmall, rowNum) {
  const gameHistorySmallTruncated = gameHistorySmall.slice(0, rowNum + 1);
  const pastGuessSmall = gameHistorySmall[rowNum];
  // const pastGuessSmall = gameHistorySmallTruncated[gameHistorySmallTruncated.length - 1];
  const boardToVisAndResponsibilityTextBA = getBoardToVisAndResponsibilityTextNEW(wordLength, numBoards, actualWords, gameHistorySmallTruncated);
  // console.log("boardToVisAndResponsibilityTextBA", boardToVisAndResponsibilityTextBA);
  const data = Array(numBoards).fill(null);
  for (let boardNum = 0; boardNum < numBoards; boardNum++) {
    const [beforeVis, ] = boardToVisAndResponsibilityTextBA.before[boardNum];
    if (beforeVis.isInProgress) {
      const [trueVis, responsibilitiesText] = boardToVisAndResponsibilityTextBA.true[boardNum];
      const optionsToDisplay = getOptionsToDisplay(beforeVis, trueVis);
      data[boardNum] = {
        currentGuess: pastGuessSmall.currentGuess,
        pattern: computePattern(actualWords[boardNum], pastGuessSmall.currentGuess),
        numOptionsBefore: beforeVis.numOptions,
        numOptionsAfter: trueVis.numOptions,
        options: optionsToDisplay,
        progressBefore: beforeVis.progress,
        progressAfter: trueVis.progress,
        networkBefore: beforeVis.network,
        networkAfter: trueVis.network,
        responsibilitiesText: responsibilitiesText,
      };
    }
  }

  return data;
}

function checkIfNetworkStatesCondition(network, condition, conditionValue) {
  const [letter, condType, number] = condition;

  if (condType === IN_POSITION) {
    if (conditionValue) {
      return network[letter][number].minCapacity === 1;
    } else {
      return network[letter][number].maxCapacity === 0;
    }
  } else if (condType === WITH_MULT) {
    if (conditionValue) {
      return network['*'][letter].minCapacity >= number;
    } else {
      return network['*'][letter].maxCapacity < number;
    }
  } else {
    console.error("checkIfNetworkStatesCondition: condType must be IN_POSITION or WITH_MULT");
  }
}

function applyConditionToNetworkInPlace(network, condition, conditionValue) {
  const [letter, condType, number] = condition;

  if (condType === IN_POSITION) {
    if (conditionValue) {
      network[letter][number].minCapacity = Math.max(network[letter][number].minCapacity, 1);
    } else {
      network[letter][number].maxCapacity = Math.min(network[letter][number].maxCapacity, 0);
    }
  } else if (condType === WITH_MULT) {
    if (conditionValue) {
      network['*'][letter].minCapacity = Math.max(network['*'][letter].minCapacity, number);
    } else {
      network['*'][letter].maxCapacity = Math.min(network['*'][letter].maxCapacity, number - 1);
    }
  } else {
    console.error("applyConditionToNetworkInPlace: condType must be IN_POSITION or WITH_MULT");
  }
}

function copyNetwork(network) {
  const newNetwork = {}
  for (const v in network) {
    newNetwork[v] = {};
    for (const w in network[v]) {
      newNetwork[v][w] = {...network[v][w]};
    }
  }

  return newNetwork
}

export function applyConditionListToNetworkAsCopy(network, valuedConditions) {
  const newNetwork = copyNetwork(network);
  // console.log("valuedConditions", valuedConditions);
  for (let i = 0; i < valuedConditions.length; i++) {
    const condition = valuedConditions[i].condition;
    const value = valuedConditions[i].value;
    applyConditionToNetworkInPlace(newNetwork, condition, value);
  }
  return newNetwork;
}

function checkIfNetworkImpliesCondition(network, flowStart, condition, conditionValue) {
  // if (condition[0] === 'g' && condition[1] === WITH_MULT && condition[2] === 1) {
  //   console.log("!", network);
  // }
  let flow = flowStart;
  if (flowStart === null) {
    // [ , flow] = makeNewPuzzleNetworkAndFlow();
    console.error("checkIfNetworkImpliesCondition: flowStart should not be null")
  }

  const editedNetwork = copyNetwork(network);
  applyConditionToNetworkInPlace(editedNetwork, condition, !conditionValue);
  const [success, ] = fixFlow(editedNetwork, flow);
  return !success;
}

function checkIfWordlistImpliesCondition(actualToLiesLeft, condition, conditionValue) {
  // console.error("checkIfWordlistImpliesCondition: TODO");
  for (const actualOption in actualToLiesLeft) {
    if (checkConditionOne(actualOption, condition) !== conditionValue) {
      return false;
    }
  }
  return true;
}

// const KNOWN_FROM_ASKING = "asking";
// const KNOWN_FROM_DEDUCTION = "implies";
// const KNOWN_FROM_WORDLIST = "wordlist";
// const NOT_KNOWN = "not";

// function checkIfConditionKnown(actualToLiesLeft, network, flowStart, condition, conditionValue) {
//   if (checkIfNetworkStatesCondition(network, condition, conditionValue)) {
//     return KNOWN_FROM_ASKING;
//   } else if (checkIfNetworkImpliesCondition(network, flowStart, condition, conditionValue)) {
//     return KNOWN_FROM_DEDUCTION;
//   } else if (checkIfWordlistImpliesCondition(actualToLiesLeft, condition, conditionValue)) {
//     return KNOWN_FROM_WORDLIST;
//   } else {
//     return NOT_KNOWN;
//   }
// }