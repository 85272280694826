import { useEffect, useRef } from 'react';
import { makeAllNeutral, makeAllSatisfied, checkGameStatusStandalone, checkWinOneBoard, makeAllBlank, isAllNeutral, getBoardToNetworkBA, computePattern, getIsWinByBoardBA, } from './Mechanics.js';
import { getFreshToAnimateIfNull, getIsStillAnimatingClue } from './Animate.js';
import { TURN_LIMIT,
  HIT, CLOSE, MISS, BLANK, UNEXPLORED, UNTOUCHED, NEUTRAL,
  CSS_CLASS, MAJOR, MINOR, UNSHARED, SHARE_SUCCESS, SHARE_ERROR, CSS_SHARE_COPY, SHARE_COPY_CONTENT,
  LETTER_SATISFIED, LETTER_UNSATIFIED,
  TYPING_ROW, CLUED_ROW, NEUTRAL_ROW, GHOST_ROW, HIDDEN_ROW, GIVEN_UP_ROW } from './Constants.js';
import { getAimFromNetwork, getMoralDataOneRowNEW } from './Moral.js';


function lookupIsPostgameOneRow(isGameOverFinal, postgameRows, rowNum) {
  return isGameOverFinal && postgameRows !== null && rowNum < postgameRows.length && postgameRows[rowNum];
}

function lookupMoralDataOneRow(moralData, rowNum) {
  return (moralData === null || rowNum >= moralData.length) ? null : moralData[rowNum];
}

// function lookupMoralDataOneRowNEW(wordLength, numBoards, actualWords, gameHistorySmall, rowNum) {
//   if (isMoral && 0 < rowNum && rowNum <= gameHistorySmall.length) {
//     const gameHistorySmallTruncated = gameHistorySmall.slice(0, rowNum + 1);
//     return getMoralDataOneRowNEW(wordLength, numBoards, actualWords, gameHistorySmallTruncated)
//   } else {
//     return null;
//   }
// }

export function GameAreaNEW({
  // // from game state
  // wordLength,
  // numBoards,
  // actualWords,
  // gameHistorySmall,
  // currentGuess,
  // message,
  // emphasizedBoardNum,
  // shareCopyStatus,
  // postgameRows,
  // basically a copy of gameState so let's take that instead
  gameState,

  // toAnimatePastRows,
  // toAnimateActiveRow,
  // isStillAnimatingClue,
  // isStillAnimatingGivenUp,
  
  // isOverridingTurnLimit,
  // isGivenUp,

  // computed properties
  // moralData,

  // isTurnLimitEndingReady,
  // isWinReady,
  // isGameOverForNowReady,
  // isGameOverFinalReady,
  // isGivenUpComing,

  // functions
  doToggleBoardEmphasis,
  doTogglePostgameRows,

  doGiveUp,
  setIsOverridingTurnLimit,
  doShare,
  doNewGame,
  
  // ???
  // isDaily,
  toggleIsDaily,

  doHandleClueAnimationEnd,
  doHandleGivenUpAnimationEnd,

  // computed now in App.js
  emphasizedBoardNumReady,
  isGameOverFinalReady,
  toAnimateNotNull,
  isStillAnimatingClue,
  isGameOverForNowReady,
  isWinByBoardAfter,
  isWinByBoardReady,
  numTurnsReady,
  isWinReady,
  numTurns,
  isTurnLimitEndingReady,
}) {
  // console.log("NEW gameState", gameState);
  const { gameParams, viewingId, toAnimate,
    actualWords,
    gameHistorySmall,
    currentGuess,
    message,
    // emphasizedBoardNum,
    shareCopyStatus,
    postgameRows,
    isOverridingTurnLimit,
    isGivenUp,
  } = gameState;
  const {
    wordLength,
    numBoards,
    isDaily
  } = gameParams;
  
  // console.log("!isStillAnimatingClue && !isGameOverForNowReady", !isStillAnimatingClue, !isGameOverForNowReady)

  const boardToNetworkFullBA = getBoardToNetworkBA(wordLength, numBoards, actualWords, gameHistorySmall);
  return (
    <div className="game-area">
      <div className="gameboard-block">
        {gameHistorySmall.map((historyOne, turnNum) => {
          const guessedWord = historyOne.currentGuess;
          const chosenBoardNum = historyOne.chosenBoardNum;
          const isPostgameOneRow = lookupIsPostgameOneRow(isGameOverFinalReady, postgameRows, turnNum);
          const toAnimateOneRow = toAnimateNotNull.pastRows[turnNum];
          {/* console.log("toAnimateNotNull", guessedWord, toAnimateNotNull); */}
          {/* const moralDataOneRow = lookupMoralDataOneRow(moralData, turnNum); */}
          const moralDataOneRow = isPostgameOneRow ? getMoralDataOneRowNEW(wordLength, numBoards, actualWords, gameHistorySmall, turnNum) : null;
          {/* console.log("moralDataOneRow", turnNum, moralDataOneRow); */}
          const onClickFullrow = (isGameOverFinalReady ?
              () => doTogglePostgameRows(turnNum) :
              null);
          const gameHistorySmallTruncated = gameHistorySmall.slice(0, turnNum + 1);
          const isWinByBoardTruncatedBefore = getIsWinByBoardBA(wordLength, numBoards, actualWords, gameHistorySmallTruncated).before;
          const boardToNetworkTruncatedBefore = getBoardToNetworkBA(wordLength, numBoards, actualWords, gameHistorySmallTruncated).before;
          return (
            <GameboardFullRowNEW key={turnNum} isPostgameOneRow={isPostgameOneRow} onClick={onClickFullrow}>
              {Array(numBoards).fill(null).map((discard, boardNum) => {
                const onClickRow = (isGameOverFinalReady ? 
                    null : 
                    () => doToggleBoardEmphasis(boardNum));
                return (
                  <GameboardRowWrapperNEW key={boardNum} moralDataOneRowOneBoardToDisplay={(isPostgameOneRow && moralDataOneRow !== null) ? moralDataOneRow[boardNum] : null}>
                    <GameboardRowFromHistoryNEW key={boardNum}
                      wordLength={wordLength}
                      actualWord={actualWords[boardNum]}
                      guessedWord={guessedWord}
                      isChosenBoard={boardNum === chosenBoardNum}
                      isEmphasized={boardNum === emphasizedBoardNumReady}
                      isPostgameOneRow={isPostgameOneRow}
                      toAnimateOneRow={toAnimateOneRow}
                      onLastFlipAnimationEnd={(event) => doHandleClueAnimationEnd(event, viewingId, turnNum)}
                      onClick={onClickRow}

                      isWinOneBoardBefore={isWinByBoardTruncatedBefore[boardNum]}
                      networkBefore={boardToNetworkTruncatedBefore[boardNum]}
                    />
                  </GameboardRowWrapperNEW>
                )
              })}
            </GameboardFullRowNEW>
          );
        })}

        {!isStillAnimatingClue && !isGameOverForNowReady && !isGivenUp ?
            <GameboardFullRowNEW isPostgameOneRow={false} onClick={null}>
              {isWinByBoardReady.map((isWinOneBoardReady, boardNum) => {
                if (isWinOneBoardReady) {
                  return (
                    <GameboardRowWrapperNEW key={boardNum} moralDataOneRowOneBoardToDisplay={null}>
                      <GameboardRowNEW
                          wordLength={wordLength} 
                          rowType={HIDDEN_ROW}
                          word={""}
                          pattern={makeAllNeutral(wordLength)}
                          network={null}
                          lettersSatisfied={makeAllSatisfied(wordLength)}
                          onClick={null}
                          isEmphasized={false}
                          toAnimateOneRow={null}
                          onLastFlipAnimationEnd={null} />
                    </GameboardRowWrapperNEW>
                  );
                } else {
                  return (
                    <GameboardRowWrapperNEW key={boardNum} moralDataOneRowOneBoardToDisplay={null}>
                      <GameboardRowNEW key={boardNum} 
                        wordLength={wordLength} 
                        rowType={TYPING_ROW}
                        word={currentGuess}
                        pattern={makeAllBlank(wordLength)}
                        network={boardToNetworkFullBA.after[boardNum]}
                        lettersSatisfied={makeAllSatisfied(wordLength)}
                        onClick={() => doToggleBoardEmphasis(boardNum)}
                        isEmphasized={boardNum === emphasizedBoardNumReady}
                        toAnimateOneRow={toAnimateNotNull.activeRow} />
                    </GameboardRowWrapperNEW>
                  );
                }
              })}
            </GameboardFullRowNEW> :
            null
        }

        {(isGivenUp) ? 
            <GameboardFullRowNEW isPostgameOneRow={false} onClick={null}>
              {isWinByBoardAfter.map((isWinOneBoardAfter, boardNum) => {
                if (isWinOneBoardAfter) {
                  return (
                    <GameboardRowWrapperNEW key={boardNum} moralDataOneRowOneBoardToDisplay={null}>
                      <GameboardRowNEW
                          wordLength={wordLength} 
                          rowType={HIDDEN_ROW}
                          word={""}
                          pattern={makeAllNeutral(wordLength)}
                          network={null}
                          lettersSatisfied={makeAllSatisfied(wordLength)}
                          onClick={null}
                          isEmphasized={false}
                          toAnimateOneRow={null}
                          onLastFlipAnimationEnd={null} />
                    </GameboardRowWrapperNEW>
                  );
                } else {
                  return (
                    <GameboardRowWrapperNEW key={boardNum} moralDataOneRowOneBoardToDisplay={null} onAnimationEnd={(event) => doHandleGivenUpAnimationEnd(event, viewingId)}>
                      <GameboardRowNEW key={boardNum} 
                        wordLength={wordLength} 
                        rowType={GIVEN_UP_ROW}
                        word={actualWords[boardNum]}
                        pattern={makeAllBlank(wordLength)}
                        network={boardToNetworkFullBA.after[boardNum]}
                        lettersSatisfied={makeAllSatisfied(wordLength)}
                        onClick={null}
                        isEmphasized={boardNum === emphasizedBoardNumReady}
                        toAnimateOneRow={toAnimateNotNull.givenUpRow} />
                    </GameboardRowWrapperNEW>
                  );
                }
              })}
            </GameboardFullRowNEW> :
            null
        }
      </div>
      {message !== "" || isGameOverForNowReady ? null : <div className="turn-counter">{numTurnsReady + 1}/{TURN_LIMIT}</div>}
      {isWinReady ? <div className="message win">You Win! You used {numTurns}/{TURN_LIMIT} turns.</div> : null}
      {/* {message !== "" || isGameOverForNowReady ? null : <div className="turn-counter">TURN {numTurnsReady + 1}{numTurnsReady + 1 <= TURN_LIMIT ? " OF " + TURN_LIMIT : ""}</div>}
      {isWinReady ? <div className="message win">You Win! You used {numTurns}{numTurns <= TURN_LIMIT ? " of " + TURN_LIMIT : ""} turns.</div> : null} */}
      {message && !(isGivenUp && toAnimateNotNull.givenUpRow.isStillAnimatingGivenUp) ? <div className={"message" + (toAnimateNotNull.givenUpRow.isAnimateGivenUp ? " should-animate-game-over-row" : "")}>{message}</div> : null}
      {!isGameOverFinalReady && isTurnLimitEndingReady ? <GameOverForNowRow doGiveUp={doGiveUp} setIsOverridingTurnLimit={setIsOverridingTurnLimit} /> : null}
      {isGameOverFinalReady ? <GameOverRow shareCopyStatus={shareCopyStatus} doShare={doShare} doNewGame={doNewGame} isDaily={isDaily} toggleIsDaily={toggleIsDaily} /> : null}
    </div>
  );
}


function GameboardFullRowNEW({
  isPostgameOneRow, 
  onClick,
  children
}) {
  const fullrowRef = useRef(null);

  useEffect(() => {
    if (isPostgameOneRow) {
      fullrowRef.current.scrollIntoView({behavior: "smooth", block: "nearest"});
    }
  }, [isPostgameOneRow]);

  // console.log("GameboardFullRowNEW children", children)

  return (
    <div className="gameboard-fullrow-wrapper" onClick={onClick}>
      <div ref={fullrowRef} style={{transform: "translateY(-60px)"}}/>
      <div className="gameboard-fullrow">
        {children}
      </div>
      {isPostgameOneRow ?
        <div className="gameboard-fullrow">
          {/* hello */}
        </div> :
        null}
    </div>
  );
}

function GameboardRowWrapperNEW({
  moralDataOneRowOneBoardToDisplay,
  onAnimationEnd,
  children
}) {
  return (<div className="gameboard-row-wrapper" onAnimationEnd={onAnimationEnd}>
    {children}
    {moralDataOneRowOneBoardToDisplay !== null ?
      <MoralRow moralDataOneRowOneBoard={moralDataOneRowOneBoardToDisplay} /> :
      null}
  </div>);
}

function GameboardRowFromHistoryNEW({
  wordLength,
  actualWord,
  guessedWord,
  isChosenBoard,
  isEmphasized,
  isPostgameOneRow,
  toAnimateOneRow,
  onLastFlipAnimationEnd,
  onClick,
  
  isWinOneBoardBefore,
  networkBefore,
}) {
  if (isWinOneBoardBefore) {
    return (
      <GameboardRowNEW
          wordLength={wordLength} 
          rowType={HIDDEN_ROW}
          word={guessedWord}
          pattern={makeAllNeutral(wordLength)}
          network={networkBefore}
          lettersSatisfied={makeAllSatisfied(wordLength)}
          onClick={null}
          isEmphasized={false}
          toAnimateOneRow={toAnimateOneRow}
          onLastFlipAnimationEnd={null} />
    )
  } else if (isChosenBoard || isPostgameOneRow) {
    const pattern = computePattern(actualWord, guessedWord);
    return (
      <GameboardRowNEW
          wordLength={wordLength} 
          rowType={isChosenBoard ? CLUED_ROW : GHOST_ROW}
          word={guessedWord}
          pattern={pattern}
          network={networkBefore}
          lettersSatisfied={makeAllSatisfied(wordLength)}
          onClick={onClick}
          isEmphasized={isEmphasized}
          toAnimateOneRow={toAnimateOneRow}
          onLastFlipAnimationEnd={isChosenBoard ? onLastFlipAnimationEnd : null} />
    );
  } else {
    return (
      <GameboardRowNEW 
          wordLength={wordLength} 
          rowType={NEUTRAL_ROW}
          word={guessedWord}
          pattern={makeAllNeutral(wordLength)}
          network={networkBefore}
          lettersSatisfied={makeAllSatisfied(wordLength)}
          onClick={onClick}
          isEmphasized={isEmphasized}
          toAnimateOneRow={toAnimateOneRow}
          onLastFlipAnimationEnd={null} />
    )
  }
}

function MoralRow({moralDataOneRowOneBoard}) {
  return (<>
    <div className="progress-bar" style={{background: "linear-gradient(to right, var(--unexplored-bg) 0%, var(--unexplored-bg) " + (moralDataOneRowOneBoard.progressBefore * 100).toFixed(1) + "%, var(--hit-bg) " + (moralDataOneRowOneBoard.progressBefore * 100).toFixed(1) + "%, var(--hit-bg) " + (moralDataOneRowOneBoard.progressAfter * 100).toFixed(1) + "%, var(--miss-bg) " + (moralDataOneRowOneBoard.progressAfter * 100).toFixed(1) + "%, var(--miss-bg) 100%)"}} />
    <div style={{fontSize: "90%"}}>+{((moralDataOneRowOneBoard.progressAfter - moralDataOneRowOneBoard.progressBefore) * 100).toFixed(0)}% progress</div>
    <div style={{fontSize: "70%", color: "#808080"}}>
      {moralDataOneRowOneBoard.numOptionsBefore} &rarr; {moralDataOneRowOneBoard.numOptionsAfter} word{moralDataOneRowOneBoard.numOptionsAfter === 1 ? "" : "s"}
      {(moralDataOneRowOneBoard.options !== null) ?
        <><br /><em>{moralDataOneRowOneBoard.options}</em></> :
        null}
    </div>
    <div style={{fontSize: "70%", textAlign: "left"}}>{moralDataOneRowOneBoard.responsibilitiesText}</div>
  </>);
}

export function GameboardRowNEW({
  wordLength,
  rowType,
  word,
  pattern,
  network,
  lettersSatisfied,
  onClick,
  isEmphasized,
  toAnimateOneRow,
  onLastFlipAnimationEnd,
}) {
  // console.log("GameboardRowNEW", 
  //   wordLength,
  //   rowType,
  //   word,
  //   pattern,
  //   network,
  //   lettersSatisfied,
  //   onClick,
  //   isEmphasized,
  //   toAnimateOneRow,
  // );
  let animateClueClass = "";
  // let onLastFlipAnimationEnd = null;
  const isAnimateClue = toAnimateOneRow !== null && toAnimateOneRow.isAnimateClue; // TODO: Update because there may not always be an isAnimateClue field
  const isAnimateTypingRowAppearance = rowType === TYPING_ROW && toAnimateOneRow !== null && toAnimateOneRow.isAnimateTypingRowAppearance;
  const isAnimateGivenUp = rowType === GIVEN_UP_ROW && toAnimateOneRow.isAnimateGivenUp;
  // console.log("isAnimateClue, rowType", word, pattern, isAnimateClue, rowType)
  if (isAnimateClue && (rowType === NEUTRAL_ROW || rowType === GHOST_ROW)) {
    animateClueClass = " should-animate-clue-fade";
  } else if (isAnimateClue && rowType === CLUED_ROW) {
    animateClueClass = " should-animate-clue-flip";
    // onLastFlipAnimationEnd = (event) => {console.log("Animation end last flip", event.animationName);};
  }
  const letterArray = Array(wordLength).fill("");
  for (let i = 0; i < word.length; i++) {
    letterArray[i] = word[i];
  }
  // word.forEach((letter, i) => {letterArray[i] = letter;});

  return (
    <div className={"gameboard-row" 
    + animateClueClass
    + (rowType === GIVEN_UP_ROW ? ' given-up' : '')
    + (rowType === HIDDEN_ROW ? " hidden" : "")
    + (rowType === GHOST_ROW ? " ghost" : "")
    + (isEmphasized ? " emphasized" : "")
    + (isAnimateTypingRowAppearance ? " should-animate-next-row" : "")
    + (isAnimateGivenUp ? " should-animate-given-up" : "")} 
    onClick={onClick}>
      {letterArray.map((letter, i) => (
        <GameboardSquareWrapperNEW key={i} isAnimateTypedLetter={rowType === TYPING_ROW && toAnimateOneRow !== null && toAnimateOneRow.typedLettersToAnimate[i]}
            onAnimationEnd={(i === wordLength - 1) ? onLastFlipAnimationEnd : null}>
          {((isAnimateClue && (rowType === NEUTRAL_ROW || rowType === CLUED_ROW || rowType === GHOST_ROW)) || rowType === TYPING_ROW || rowType === GIVEN_UP_ROW) ?
              <GameboardSquareNEW 
                isBackside={rowType === NEUTRAL_ROW || rowType === CLUED_ROW || rowType === GHOST_ROW} 
                letter={letter}
                aim={BLANK} 
                letterAim={letter === "" ? null : getAimFromNetwork(network, letter)} 
                letterSatisfied={lettersSatisfied[i]} /> :
              null}
          {(rowType === CLUED_ROW || rowType === GHOST_ROW) ?
              <GameboardSquareNEW
                isBackside={false} 
                letter={letter}
                aim={pattern[i]}
                letterAim={null}
                letterSatisfied={LETTER_SATISFIED} /> :
              null}
          {(rowType === HIDDEN_ROW || rowType === NEUTRAL_ROW) ?
              <GameboardSquareNEW
                isBackside={false} 
                letter={""}
                aim={NEUTRAL}
                letterAim={null}
                letterSatisfied={LETTER_SATISFIED} /> :
              null}
        </GameboardSquareWrapperNEW>
      ))}
    </div>
  );
}

function GameboardSquareWrapperNEW({
  children,
  isAnimateTypedLetter,
  onAnimationEnd,
}) {
  return (<div className={"gameboard-square-wrapper" + (isAnimateTypedLetter ? " should-animate-typed-letter" : "")}
      onAnimationEnd={onAnimationEnd}>
    {children}
  </div>)
}


function GameboardSquareNEW({
  isBackside,
  letter,
  aim,
  letterAim,
  letterSatisfied,
}) {
  // const letter = children;
  const letterAimCss = (letterAim === null ? '' : ' occupied hint-' + CSS_CLASS[letterAim]);
  // console.log("GameboardSquareNEW", letter, aim, letterAim, letterAimCss);
  return (
  <div className={"gameboard-square" 
      + (isBackside ? ' backside' : ' frontside')
      + " " + CSS_CLASS[aim] 
      + letterAimCss
      + (letterSatisfied === LETTER_UNSATIFIED ? ' unsatisfied' : '')}>
    <div className="gameboard-square-inner">
      {letter.toUpperCase()}
    </div>
  </div>);
}

function GameOverForNowRow({ doGiveUp, setIsOverridingTurnLimit }) {
  return (
    <div className="game-over-row should-animate-game-over-row">
      <button className="big-button" onClick={doGiveUp}>Reveal Answer</button>
      <button className="big-button" onClick={() => setIsOverridingTurnLimit(true)}>Keep Trying</button>
    </div>
  )
}

function GameOverRow(props) {
  function setIsDailyFalse() {
    props.toggleIsDaily(false);
  }

  return (
    <div className="game-over-row should-animate-game-over-row">
      <button className={"big-button " + CSS_SHARE_COPY[props.shareCopyStatus]} onClick={props.doShare}>{SHARE_COPY_CONTENT[props.shareCopyStatus]}</button>
      {props.isDaily
        ? <button className="big-button" onClick={setIsDailyFalse}>Practice</button>
        : <button className="big-button" onClick={props.doNewGame}>New Game</button>
      }
    </div>
  )
}