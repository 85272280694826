import { ALL_LETTERS,
  HIT, CLOSE, MISS, BLANK, UNEXPLORED, NEUTRAL,
  CSS_CLASS, AIM_TO_NUMBER, MAJOR, MINOR, IMPORTANCE_CLASS,
  CONFIDENT, UNSURE, DISTRUST } from "./Constants";


// https://www.svgrepo.com/svg/361715/backspace
const BACKSPACE_ICON = (<svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.707 8.293a1 1 0 0 1 0 1.414L15.414 12l2.293 2.293a1 1 0 0 1-1.414 1.414L14 13.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L12.586 12l-2.293-2.293a1 1 0 1 1 1.414-1.414L14 10.586l2.293-2.293a1 1 0 0 1 1.414 0z" /><path fillRule="evenodd" clipRule="evenodd" d="M22 5a1 1 0 0 0-1-1H9.46a2 2 0 0 0-1.519.698l-5.142 6a2 2 0 0 0 0 2.604l5.142 6A2 2 0 0 0 9.46 20H21a1 1 0 0 0 1-1V5zm-2 13H9.46l-5.143-6L9.46 6H20v12z" /></svg>);


// keyboard computation functions

function miniMemo(fn) {
  let savedArgs = null;
  let savedValue = null;
  function g(...args) {
    if (savedArgs === null || savedArgs.length !== args.length) {
      savedArgs = args;
      savedValue = fn(...args);
      return savedValue;
    }
    for (let i = 0; i < savedArgs.length; i++) {
      if (savedArgs[i] !== args[i]) {
        savedArgs = args;
        savedValue = fn(...args);
        return savedValue;
      }
    }
    return savedValue;
  }
  return g;
}

export function computeAllKeyboardAims(wordLength, boardToPastGuesses, maxLies) {
  const numBoards = boardToPastGuesses.length;
  const keyboardAims = Array(numBoards);
  for (let boardNum = 0; boardNum < numBoards; boardNum++) {
    const pastGuesses = boardToPastGuesses[boardNum];
    keyboardAims[boardNum] = computeKeyboardAimMemo(wordLength, pastGuesses, maxLies); // TODO: This probably isn't memoizing usefully
  }
  return keyboardAims;
}


function computeKeyboardAimStandalone(wordLength, pastGuesses, 
  // pastRelativeTrust, relativeToAbsoluteTrust, numTrustLess, 
  maxLies) { // does not use "this"
  // const confidentThreshold = (numTrustLess !== null && numTrustLess < maxLies ? maxLies - numTrustLess + 1 : maxLies + 1);
  const confidentThreshold = maxLies + 1;
  const keyboardAimDetailed = computeKeyboardAimDetailedStandalone(wordLength, pastGuesses, 
    // pastRelativeTrust, relativeToAbsoluteTrust, 
    confidentThreshold);

  const keyboardAimSummary = {}
  for (const letter in keyboardAimDetailed) {
    const letterAim = keyboardAimDetailed[letter];
    const numHitByIndex = letterAim.numHitByIndex;
    const numHitClose = letterAim.numHitClose;
    const numMiss = letterAim.numMiss;
    let maxHit = 0;
    numHitByIndex.forEach((count, index) => {
      if (count > maxHit) {maxHit = count;}
    })
    let highMarks = {1: UNEXPLORED, [confidentThreshold]: UNEXPLORED};
    for (const threshold in highMarks) {
      if (maxHit >= threshold) {highMarks[threshold] = HIT;}
      else if (numHitClose >= threshold) {highMarks[threshold] = CLOSE;}
      else if (numMiss >= threshold) {highMarks[threshold] = MISS;}
    }
    keyboardAimSummary[letter] = [highMarks[1], highMarks[confidentThreshold]];
  }
  return keyboardAimSummary;
}

function computeKeyboardAimDetailedStandalone(wordLength, pastGuesses, 
  // pastRelativeTrust, relativeToAbsoluteTrust, 
  confidentThreshold) { // doesn't use "this" // I think this doesn't need to be separated from computeKeyboardAimStandalone after all
  const keyboardAimDetailed = {};
  ALL_LETTERS.forEach((letter) => {keyboardAimDetailed[letter] = {numHitByIndex: Array(wordLength).fill(0), numHitClose: 0, numMiss: 0}})
  pastGuesses.forEach((wordInfo, rowNum) => {
    // const trust = ((pastRelativeTrust === null) ? UNSURE : relativeToAbsoluteTrust[pastRelativeTrust[rowNum]]);
    const trust = UNSURE;
    const weight = {[DISTRUST]: 0, [UNSURE]: 1, [CONFIDENT]: confidentThreshold}[trust];
    if ((trust === UNSURE) || (trust === CONFIDENT)) {
      const uniqueLetterAim = {};
      for (const i in wordInfo.guessedWord) {
        const letter = wordInfo.guessedWord[i];
        const aim = wordInfo.pattern[i];
        if (aim === HIT) {keyboardAimDetailed[letter].numHitByIndex[i] += weight;}
        if (letter in uniqueLetterAim) {
          if (higherAim(aim, uniqueLetterAim[letter])) {uniqueLetterAim[letter] = aim;}
        } else {
          uniqueLetterAim[letter] = aim;
        }
      }
      for (const letter in uniqueLetterAim)
      {
        const aim = uniqueLetterAim[letter];
        if (aim === HIT || aim === CLOSE) {keyboardAimDetailed[letter].numHitClose += weight;}
        else if (aim === MISS) {keyboardAimDetailed[letter].numMiss += weight;}
        else {console.log("should not reach this line of code");}
      }
    }
  })
  return keyboardAimDetailed;
}

function higherAim(aim1, aim2) {
  return (AIM_TO_NUMBER[aim1] > AIM_TO_NUMBER[aim2]);
}

function computeUnexploredCountsStandalone(keyboardAims) { // does not use "this"  const keyboardAimDetailed = {};
  const unexploredCounts = {};
  ALL_LETTERS.forEach((letter) => {
    unexploredCounts[letter] = 0;
    keyboardAims.forEach((keyboardAimOneBoard, index) => {
      if (keyboardAimOneBoard[letter][0] === UNEXPLORED) {unexploredCounts[letter]++;}
    })
  })
  return unexploredCounts;
}

const computeKeyboardAimMemo = miniMemo(computeKeyboardAimStandalone);
const computeUnexploredCounts = miniMemo(computeUnexploredCountsStandalone);

function getTextCentralClassAndImportanceClass(letterAims, importanceComponents, canOverrideImportance) {
  const numBoards = letterAims.length;
  const trueImportanceComponents = Array(numBoards);
  var isAllUnexplored = true;
  var isAllMiss = true;
  letterAims.forEach((aimTuple, boardNum) => {
    const aim = aimTuple[0];
    // letterAims[boardNum] = keyboardAim[props.letter];
    trueImportanceComponents[boardNum] = importanceComponents[boardNum];
    if (canOverrideImportance && aim !== UNEXPLORED) {
      trueImportanceComponents[boardNum] = MINOR;
    }
    if (aim !== UNEXPLORED) {isAllUnexplored = false;}
    if (aim !== MISS) {isAllMiss = false;}
  });
  var textCentralClass;
  if (isAllUnexplored) {
    textCentralClass = "all-unexplored";
  } else if (isAllMiss) {
    textCentralClass = "all-miss";
  } else {
    textCentralClass = "all-mixed";
  }
  const importanceClass = trueImportanceComponents.map((importance) => {
    return IMPORTANCE_CLASS[importance]
  }).join('-');
  const keyboardImportanceComponents = [...trueImportanceComponents];
  if (keyboardImportanceComponents.every(importance => (importance === MINOR))) {
    keyboardImportanceComponents.fill(MAJOR);
  }
  return [textCentralClass, importanceClass, keyboardImportanceComponents];
}

// keyboard components

function KeyboardButtonLetter(props) {
  function onClick() {
    props.typeLetter(props.letter);
  }

  // const letterAims = Array(NUM_BOARDS);
  const letterAims = props.letterAims
  const numBoards = letterAims.length;
  const [textCentralClass, importanceClass, keyboardImportanceComponents] = getTextCentralClassAndImportanceClass(letterAims, props.importanceComponents, props.canOverrideImportance);
  // const letterAims = props.letterAims;
  const isShapes = (numBoards === 2 && props.isShapeKeyboard);
  const css_classes = Array(2);
  for (let i = 0; i < 2; i++) {
    css_classes[i] = Array(numBoards);
    for (let boardNum = 0; boardNum < numBoards; boardNum++) {
      css_classes[i][boardNum] = CSS_CLASS[letterAims[boardNum][i]];
    }
  }
  // console.log(props.letter, keyboardImportanceComponents, letterAims);
  const importantUnexploredClass = "important-unexplored-list" + letterAims.map((aimList, boardNum) => ((keyboardImportanceComponents[boardNum] === MAJOR && aimList[0] === UNEXPLORED) ? "-" + boardNum : "")).join("");
  const outerClass = (isShapes ? css_classes[1][0] : css_classes[1].join('-'));
  const innerClass = (isShapes ? css_classes[1][1] : css_classes[0].join('-'));
  return <button className={"keyboard-button letter " + outerClass + " " + importanceClass + " " + importantUnexploredClass} value={props.letter} onClick={onClick}>
    <div className={"keyboard-button-inner letter " + innerClass}>
      {/* <div className={"text-central unexplored"}> */}
      {props.letter.toUpperCase()}
      {/* </div> */}
    </div>
    {/* {css_classes[0].map((individualClass, index) => {
        return (<div className={"keyboard-button-inner letter " + innerClass + " " + importanceClass + "-" + index + " inner-" + index + "-" + NUM_BOARDS + " single text-" + CSS_CLASS[letterAims[index][0]]} key={index}>
              {props.letter.toUpperCase()} */}
            {/* <div className={"text-" + index + "-" + NUM_BOARDS + " " + individualClass}>{props.letter.toUpperCase()}</div> */}
          {/* </div>);
    })} */}
  </button>;
  // return <button className={"keyboard-button letter " + CSS_CLASS[letterAim[1]]} value={props.letter} onClick={onClick}>
  //   <div className={"keyboard-button-inner letter " + CSS_CLASS[letterAim[0]]}>{props.letter.toUpperCase()}</div>
  // </button>;
}

function MiniKeyboardButtonLetter(props) {
  var letterAim = props.letterAim;
  // if (letterAim === UNEXPLORED && props.isTwiceUnexplored) {
  //   letterAim = UNTOUCHED;
  // }
  const individualClass = CSS_CLASS[letterAim];
  return (<div className={"mini-keyboard-button letter " + individualClass} value={props.letter}>
    {props.letter.toUpperCase()}
  </div>);
}

function KeyboardExplainerRow(props) {
  return (<div className="keyboard-explainer-row">
    <div className="keyboard-explainer-letter-wrapper">
    <KeyboardButtonLetter letter={props.letter} typeLetter={() => {}} letterAim={props.letterAim} />
    </div>
    <div className="keyboard-explainer-text">{props.children}</div>
  </div>)
}

function KeyboardButtonWide(props) {
  return (<button className="keyboard-button wide" value={props.value} onClick={props.onClick}>
    {props.text}
  </button>);
}

function KeyboardRow(props) {
  const numBoards = props.keyboardAims.length;
  return (
    <div className="keyboard-row">
      {props.first}
      {[...props.letters].map((letter) => {
        const letterAims = Array(numBoards);
        props.keyboardAims.forEach((keyboardAim, boardNum) => {
          letterAims[boardNum] = keyboardAim[letter];
        })
        /* props.keyboardAim[letter] */
        return (<KeyboardButtonLetter key={letter} letter={letter} typeLetter={props.typeLetter} letterAims={letterAims} isShapeKeyboard={props.isShapeKeyboard} importanceComponents={props.importanceComponents} canOverrideImportance={props.canOverrideImportance} />);
      })}
      {props.last}
    </div>
  );
}


function MiniKeyboardRow(props) {
  return (
    <div className="mini-keyboard-row">
      {[...props.letters].map((letter) => {
        return (<MiniKeyboardButtonLetter key={letter} letter={letter} letterAim={props.keyboardAim[letter][0]} isTwiceUnexplored={props.unexploredCounts[letter] === 2} />);
      })}
    </div>
    // The fact that we need to take component 0 (in props.keyboardAim[letter][0]) is left over from absurdliar where there can be different numbers of lies.
  );
}

function Keyboard(props) {
  const enterButton = <KeyboardButtonWide value="enter" text="ENTER" onClick={props.tryEnter} />; 
  const backspaceButton = <KeyboardButtonWide value="backspace" text={props.canUndoWord ? "UNDO GUESS" : BACKSPACE_ICON} onClick={props.typeBackspace} />; 
  return (
    <div className="keyboard">
      <KeyboardRow letters="qwertyuiop" typeLetter={props.typeLetter} keyboardAims={props.keyboardAims} isShapeKeyboard={props.isShapeKeyboard} importanceComponents={props.importanceComponents} canOverrideImportance={props.canOverrideImportance} />
      <KeyboardRow letters="asdfghjkl" typeLetter={props.typeLetter} keyboardAims={props.keyboardAims} isShapeKeyboard={props.isShapeKeyboard} importanceComponents={props.importanceComponents} canOverrideImportance={props.canOverrideImportance} />
      <KeyboardRow letters="zxcvbnm" typeLetter={props.typeLetter} keyboardAims={props.keyboardAims} isShapeKeyboard={props.isShapeKeyboard} importanceComponents={props.importanceComponents} canOverrideImportance={props.canOverrideImportance} first={enterButton} last={backspaceButton} />
    </div>
  )
}

function MiniKeyboard(props) {
  return (
    <div className="gameboard">
      <div className="mini-keyboard">
        <MiniKeyboardRow letters="qwertyuiop" keyboardAim={props.keyboardAim} unexploredCounts={props.unexploredCounts} />
        <MiniKeyboardRow letters="asdfghjkl" keyboardAim={props.keyboardAim} unexploredCounts={props.unexploredCounts} />
        <MiniKeyboardRow letters="zxcvbnm" keyboardAim={props.keyboardAim} unexploredCounts={props.unexploredCounts} />
      </div>
    </div>
  )
}

export function KeyboardArea(props) {
  // function setIsMiniKeyboardLSTrue() {
  //   props.setIsMiniKeyboardLS(true);
  // }

  // function setIsMiniKeyboardLSFalse() {
  //   props.setIsMiniKeyboardLS(false);
  // }
  // 
  // var miniKeyboardBlock;
  // if (props.isMiniKeyboard) {
  //   const unexploredCounts = computeUnexploredCounts(props.keyboardAims);
  //   miniKeyboardBlock = (<div className="mini-keyboard-block" onClick={setIsMiniKeyboardLSFalse}>
  //       {props.keyboardAims.map((keyboardAim, index) => {
  //         /* return (<div className="gameboard" key={index}>Gameboard {index}</div>); */
  //         return <MiniKeyboard keyboardAim={keyboardAim} unexploredCounts={unexploredCounts} key={index} />
  //       })}
  //     </div>);
  // } else {
  //   miniKeyboardBlock = (<div className="show-banks-row">
  //     <button  className="show-banks-button" onClick={setIsMiniKeyboardLSTrue}>Show letter banks</button>
  //   </div>);
  // }
  return (<div className="keyboard-area" ref={props.keyboardAreaRef}>
    {/* {miniKeyboardBlock} */}
    <Keyboard keyboardAims={props.keyboardAims} typeLetter={props.typeLetter} tryEnter={props.tryEnter} typeBackspace={props.typeBackspace} canUndoWord={props.canUndoWord} isShapeKeyboard={props.isShapeKeyboard} importanceComponents={props.importanceComponents} canOverrideImportance={props.canOverrideImportance} />
  </div>);
}