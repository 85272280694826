import Switch from "react-switch";

const TWITTER_LINK = 'https://twitter.com/intent/tweet?screen_name=wreathproduct';

export function SettingsModal(props) {
  function setIsShowSettingsFalse() {
    props.setIsShowSettings(false);
  }

  // const atLeastHardMode = (props.whichHardMode === HARD_MODE || props.whichHardMode === EVEN_HARDER_MODE);
  return (
    <div className={"modal rules " + (props.isShowSettings ? "show" : "hide")}>
      <div className="shade" onClick={setIsShowSettingsFalse} />
      <div className="main-column">
        <div className="modal-header">
          <div className="modal-close-button" />
          <div className="modal-title">Settings</div>
          <button className="modal-close-button" value="close-settings" onClick={setIsShowSettingsFalse}>&times;</button>
        </div>
        <div className="modal-content">
          <div className="settings-row">
            <button className="big-button" onClick={props.doGiveUp}>Give Up</button>
            <button className="big-button" onClick={props.doNewGame}>New Game</button>
          </div>
          {/* {props.maxLiesAddMessage ? <div className="message max-lies-add-message">{props.maxLiesAddMessage}</div> : null}
          <OneSetting name="Number of Lies" control={<NumberInput value={props.maxLies} add={props.maxLiesAdd} off={!props.isNewGame} />} >
            The computer is allowed to lie (give you incorrect colors) in response to this many guesses.
          </OneSetting>
          <OneSettingSwitch name="Hard Mode" onChange={props.toggleHardMode} checked={atLeastHardMode} extraClassNames={(atLeastHardMode ? " continues" : null)}>
            If a letter must be in the secret word (because you've received multiple clues about it, more than the number of lies), then it must be in your future guesses.
          </OneSettingSwitch>
          {(atLeastHardMode
            ?
              <div className="settings-row">
                <OneSettingSwitch name="Even Harder Mode" onChange={props.toggleEvenHarderMode} checked={props.whichHardMode === EVEN_HARDER_MODE} extraClassNames=" continued">
                  Every guess must be a valid possibility for the secret word &mdash; your guess can't contradict multiple past clues (more than the number of lies).
                </OneSettingSwitch>
              </div>
            : null)}
          <OneSettingSwitch name="Fancy keyboard coloring" onChange={props.toggleUseFancyTrust} checked={props.isFancyTrust}>
            <strong>Basic keyboard:</strong> Each letter on the keyboard is colored based on what you know about it. It assumes that every clue is accurate, but you can tap on a clue to mark it as a lie. <br />
            <strong>Fancy keyboard:</strong> Fully colored letters show information that is certain. Information that might be a lie is marked with a dot.
          </OneSettingSwitch> */}
          <OneSettingSwitch name="Dark Mode" onChange={props.setIsDarkModeLS} checked={props.isDarkMode}>
            
          </OneSettingSwitch>
          <OneSettingSwitch name="High-Contrast Colors" onChange={props.setIsHighContrastLS} checked={props.isHighContrast}>
            
          </OneSettingSwitch>
          <OneSettingSwitch name="Color keyboard outer/inner instead of left/right" onChange={props.setIsShapeKeyboardLS} checked={props.isShapeKeyboard}>
            
          </OneSettingSwitch>
          <OneSetting name="Feedback" control={<a href={TWITTER_LINK} target="blank">Twitter</a>}>

          </OneSetting>
          <div className="spacer"></div>
          <div className="version-row"><div className="left">&copy;2023 Timothy Black</div><div className="right">v2.0</div></div>
        </div>
      </div>
    </div>
  )
}

function OneSetting({name, control, extraClassNames, children}) {
  return (
    <div className={"settings-row" + (extraClassNames ? extraClassNames : '')}>
      <label>
        <div className="setting-label">
          <div className="setting-name">{name}</div>
          <div className="setting-description">{children}</div>
        </div>
        {control}
      </label>
    </div>
  )
}

function OneSettingSwitch(props) {
  const control = (
    <Switch className="switch" onChange={props.onChange} checked={props.checked} onColor="#6aaa64" width={56} height={28} />
  )
  return (
    <OneSetting name={props.name} control={control} extraClassNames={props.extraClassNames}>
      {props.children}
    </OneSetting>
  )
}

function NumberInput(props) {
  function decrement() {
    props.add(-1);
  }

  function increment() {
    props.add(1);
  }

  const value = props.value;
  return (
    <div className="number-input">
      <button className={(value === 0 || props.off) ? "off" : ""} onClick={decrement}>-</button>
      <div className="number-input-value">{value}</div>
      <button className={props.off ? "off" : ""} onClick={increment}>+</button>
    </div>
  );
}